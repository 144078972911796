import { date, number, object, string, array, ref } from "yup";

import {
  bandLevelType,
  bloodGroups,
  clientType,
  completionStatusType,
  educationLevel,
  employmentType,
  maritalStatusType,
  myProjectType,
  roleTypes,
  statusType,
  taggingType,
  workModeType,
} from "../../utils/constants/enums";
import {
  API_FIELD_NAMES,
  DEFAULT_SELECT,
  FIELD_LABELS,
  INPUT_TYPES,
} from "../../utils/constants/keywords";
import { ERROR_MESSAGES } from "../../utils/constants/messages";

const editPageInputs = {
  profilePhoto: API_FIELD_NAMES.PROFILE_PHOTO,
  Profile: [
    {
      id: 1,
      label: FIELD_LABELS.EMPLOYEE_ID,
      type: INPUT_TYPES.TEXT,
      name: API_FIELD_NAMES.EMPLOYEE_ID,
      maxLength: 7,
      required: true,
    },
    {
      id: 2,
      label: FIELD_LABELS.EMPLOYEE_NAME,
      type: INPUT_TYPES.TEXT,
      name: API_FIELD_NAMES.EMPLOYEE_NAME,
      required: true,
    },
    {
      id: 3,
      label: FIELD_LABELS.DEPARTMENT,
      type: INPUT_TYPES.TEXT,
      name: API_FIELD_NAMES.DEPARTMENT,
      required: true,
      select: true,
      selectionItems: roleTypes,
    },
    {
      id: 4,
      label: FIELD_LABELS.CONTACT_NUMBER,
      type: INPUT_TYPES.TEL,
      name: API_FIELD_NAMES.CONTACT_NUMBER,
      required: true,
      maxLength: 10,
    },
    {
      id: 5,
      label: FIELD_LABELS.EMAIL_ADDRESS,
      type: INPUT_TYPES.EMAIL,
      name: API_FIELD_NAMES.EMAIL_ADDRESS,
      required: true,
    },
    {
      id: 6,
      label: FIELD_LABELS.DATE_OF_BIRTH,
      type: INPUT_TYPES.DATE,
      name: API_FIELD_NAMES.DATE_OF_BIRTH,
      required: true,
    },
  ],
  Personal: [
    {
      id: 1,
      label: FIELD_LABELS.FATHERS_NAME,
      type: INPUT_TYPES.TEXT,
      name: API_FIELD_NAMES.FATHERS_NAME,
      required: true,
    },
    {
      id: 2,
      label: FIELD_LABELS.MOTHERS_NAME,
      type: INPUT_TYPES.TEXT,
      name: API_FIELD_NAMES.MOTHERS_NAME,
      required: true,
    },
    {
      id: 3,
      label: FIELD_LABELS.MARITAL_STATUS,
      type: INPUT_TYPES.TEXT,
      name: API_FIELD_NAMES.MARITAL_STATUS,
      required: false,
      select: true,
      selectionItems: maritalStatusType,
    },
    {
      id: 4,
      label: FIELD_LABELS.SPOUSE,
      type: INPUT_TYPES.TEXT,
      name: API_FIELD_NAMES.SPOUSE,
      required: false,
    },
    {
      id: 5,
      label: FIELD_LABELS.NO_OF_CHILDREN,
      type: INPUT_TYPES.NUMERIC,
      name: API_FIELD_NAMES.NO_OF_CHILDREN,
      required: false,
      maxLength: 1,
    },
    {
      id: 6,
      label: FIELD_LABELS.BLOOD_GROUP,
      type: INPUT_TYPES.TEXT,
      name: API_FIELD_NAMES.BLOOD_GROUP,
      required: true,
      select: true,
      selectionItems: bloodGroups,
    },
    {
      id: 7,
      label: FIELD_LABELS.EMERGENCY_CONTACT_NAME,
      type: INPUT_TYPES.TEXT,
      name: API_FIELD_NAMES.EMERGENCY_CONTACT_NAME,
      required: true,
    },
    {
      id: 8,
      label: FIELD_LABELS.EMERGENCY_CONTACT_NUMBER,
      type: INPUT_TYPES.TEL,
      name: API_FIELD_NAMES.EMERGENCY_CONTACT_NUMBER,
      required: true,
      maxLength: 10,
    },
    {
      id: 9,
      label: FIELD_LABELS.NATIONALITY,
      type: INPUT_TYPES.TEXT,
      name: API_FIELD_NAMES.NATIONALITY,
      required: true,
    },
    {
      id: 10,
      label: FIELD_LABELS.AADHAR_NO,
      type: INPUT_TYPES.NUMERIC,
      name: API_FIELD_NAMES.AADHAR_NO,
      required: true,
      maxLength: 12,
    },
    {
      id: 11,
      label: FIELD_LABELS.PAN_CARD_NO,
      type: INPUT_TYPES.TEXT,
      name: API_FIELD_NAMES.PAN_CARD_NO,
      required: true,
      maxLength: 10,
    },
    {
      id: 12,
      label: FIELD_LABELS.PASSPORT_NO,
      type: INPUT_TYPES.TEXT,
      name: API_FIELD_NAMES.PASSPORT_NO,
      required: false,
      maxLength: 12,
    },
    {
      id: 13,
      label: FIELD_LABELS.ADDRESS,
      type: INPUT_TYPES.TEXT,
      name: API_FIELD_NAMES.ADDRESS,
      required: true,
    },
    {
      id: 14,
      label: FIELD_LABELS.ZIP_CODE,
      type: INPUT_TYPES.NUMERIC,
      name: API_FIELD_NAMES.ZIP_CODE,
      required: true,
      maxLength: 6,
    },
  ],
  Education: {
    [API_FIELD_NAMES.SPECIALIZATION_OF_STUDY]: [
      {
        id: 1,
        label: FIELD_LABELS.EDUCATION,
        type: INPUT_TYPES.TEXT,
        name: API_FIELD_NAMES.EDUCATION,
        required: true,
        select: true,
        selectionItems: educationLevel,
        array: API_FIELD_NAMES.EDUCATION_OBJ,
      },
      {
        id: 2,
        label: FIELD_LABELS.UNIVERSITY_BOARD,
        type: INPUT_TYPES.TEXT,
        name: API_FIELD_NAMES.UNIVERSITY_BOARD,
        required: true,
        array: API_FIELD_NAMES.EDUCATION_OBJ,
      },
      {
        id: 3,
        label: FIELD_LABELS.FIELD_OF_STUDY,
        type: INPUT_TYPES.TEXT,
        name: API_FIELD_NAMES.FIELD_OF_STUDY,
        required: true,
        array: API_FIELD_NAMES.EDUCATION_OBJ,
      },
      {
        id: 4,
        label: FIELD_LABELS.START_DATE,
        type: INPUT_TYPES.DATE,
        name: API_FIELD_NAMES.START_DATE,
        required: true,
        array: API_FIELD_NAMES.EDUCATION_OBJ,
      },
      {
        id: 5,
        label: FIELD_LABELS.END_DATE,
        type: INPUT_TYPES.DATE,
        name: API_FIELD_NAMES.END_DATE,
        required: true,
        array: API_FIELD_NAMES.EDUCATION_OBJ,
      },
      {
        id: 6,
        label: FIELD_LABELS.GRADES_PERCENTAGE,
        type: INPUT_TYPES.TEXT,
        name: API_FIELD_NAMES.GRADES_PERCENTAGE,
        required: true,
        array: API_FIELD_NAMES.EDUCATION_OBJ,
        maxLength: 2,
      },
    ],
    [API_FIELD_NAMES.CERTIFICATION_DETAILS]: [
      {
        id: 1,
        label: FIELD_LABELS.CERTIFICATION_NAME,
        type: INPUT_TYPES.TEXT,
        name: API_FIELD_NAMES.CERTIFICATION_NAME,
        required: false,
        array: API_FIELD_NAMES.CERTIFICATION_OBJ,
      },
      {
        id: 2,
        label: FIELD_LABELS.INSTITUTE,
        type: INPUT_TYPES.TEXT,
        name: API_FIELD_NAMES.INSTITUTE,
        required: false,
        array: API_FIELD_NAMES.CERTIFICATION_OBJ,
      },
    ],
    [API_FIELD_NAMES.SKILLSET_OBJ]: [
      {
        id: 1,
        label: FIELD_LABELS.SKILLSET,
        type: INPUT_TYPES.TEXT,
        name: API_FIELD_NAMES.SKILL_SET,
      },
    ],
  },
  [API_FIELD_NAMES.EMPLOYMENT_DETAIL]: {
    currentEmployment: [
      {
        id: 1,
        label: FIELD_LABELS.DESIGNATION,
        type: INPUT_TYPES.TEXT,
        name: API_FIELD_NAMES.DESIGNATION,
        required: true,
        array: API_FIELD_NAMES.CURRENT_EMPLOYMENT_OBJ,
      },
      {
        id: 2,
        label: FIELD_LABELS.EMPLOYMENT_TYPE,
        type: INPUT_TYPES.TEXT,
        name: API_FIELD_NAMES.EMPLOYMENT_TYPE,
        required: true,
        select: true,
        selectionItems: employmentType,
        array: API_FIELD_NAMES.CURRENT_EMPLOYMENT_OBJ,
      },
      {
        id: 3,
        label: FIELD_LABELS.CLIENT,
        type: INPUT_TYPES.TEXT,
        name: API_FIELD_NAMES.CLIENT,
        required: true,
        select: true,
        selectionItems: clientType,
        array: API_FIELD_NAMES.CURRENT_EMPLOYMENT_OBJ,
      },
      {
        id: 4,
        label: FIELD_LABELS.TAGGING,
        type: INPUT_TYPES.TEXT,
        name: API_FIELD_NAMES.TAGGING,
        required: true,
        select: true,
        selectionItems: taggingType,
        array: API_FIELD_NAMES.CURRENT_EMPLOYMENT_OBJ,
      },
      {
        id: 5,
        label: FIELD_LABELS.STATUS,
        type: INPUT_TYPES.TEXT,
        name: API_FIELD_NAMES.STATUS,
        required: true,
        select: true,
        selectionItems: statusType,
        array: API_FIELD_NAMES.CURRENT_EMPLOYMENT_OBJ,
      },
      {
        id: 6,
        label: FIELD_LABELS.SUPERVISOR,
        type: INPUT_TYPES.TEXT,
        name: API_FIELD_NAMES.SUPERVISOR,
        required: true,
        array: API_FIELD_NAMES.CURRENT_EMPLOYMENT_OBJ,
      },
      {
        id: 7,
        label: FIELD_LABELS.MY_PROJECT,
        type: INPUT_TYPES.TEXT,
        name: API_FIELD_NAMES.MY_PROJECT,
        required: true,
        select: true,
        selectionItems: myProjectType,
        array: API_FIELD_NAMES.CURRENT_EMPLOYMENT_OBJ,
      },
      {
        id: 8,
        label: FIELD_LABELS.START_DATE,
        type: INPUT_TYPES.DATE,
        name: API_FIELD_NAMES.START_DATE,
        required: true,
        array: API_FIELD_NAMES.CURRENT_EMPLOYMENT_OBJ,
      },
      {
        id: 9,
        label: FIELD_LABELS.END_DATE,
        type: INPUT_TYPES.DATE,
        name: API_FIELD_NAMES.END_DATE,
        required: false,
        array: API_FIELD_NAMES.CURRENT_EMPLOYMENT_OBJ,
      },
    ],
    previousEmployments: [
      {
        id: 1,
        label: FIELD_LABELS.DESIGNATION,
        type: INPUT_TYPES.TEXT,
        name: API_FIELD_NAMES.DESIGNATION,
        required: true,
        array: API_FIELD_NAMES.PREVIOUS_EMPLOYMENT_OBJ,
      },
      {
        id: 2,
        label: FIELD_LABELS.EMPLOYMENT_TYPE,
        type: INPUT_TYPES.TEXT,
        name: API_FIELD_NAMES.EMPLOYMENT_TYPE,
        required: true,
        select: true,
        selectionItems: employmentType,
        array: API_FIELD_NAMES.PREVIOUS_EMPLOYMENT_OBJ,
      },
      {
        id: 3,
        label: FIELD_LABELS.TOTAL_RELEVANT_EXPERIENCE,
        type: INPUT_TYPES.TEXT,
        name: API_FIELD_NAMES.TOTAL_RELEVANT_EXPERIENCE,
        required: false,
        array: API_FIELD_NAMES.PREVIOUS_EMPLOYMENT_OBJ,
        maxLength: 3,
      },
      {
        id: 4,
        label: FIELD_LABELS.COMPANY_NAME,
        type: INPUT_TYPES.TEXT,
        name: API_FIELD_NAMES.COMPANY_NAME,
        required: true,
        array: API_FIELD_NAMES.PREVIOUS_EMPLOYMENT_OBJ,
      },
      {
        id: 5,
        label: FIELD_LABELS.COMPANY_LOCATION,
        type: INPUT_TYPES.TEXT,
        name: API_FIELD_NAMES.COMPANY_LOCATION,
        required: false,
        array: API_FIELD_NAMES.PREVIOUS_EMPLOYMENT_OBJ,
      },
      {
        id: 6,
        label: FIELD_LABELS.WORK_MODE,
        type: INPUT_TYPES.TEXT,
        name: API_FIELD_NAMES.WORK_MODE,
        required: true,
        select: true,
        selectionItems: workModeType,
        array: API_FIELD_NAMES.PREVIOUS_EMPLOYMENT_OBJ,
      },
      {
        id: 7,
        label: FIELD_LABELS.BAND_LEVEL,
        type: INPUT_TYPES.TEXT,
        name: API_FIELD_NAMES.BAND_LEVEL,
        required: false,
        select: true,
        selectionItems: bandLevelType,
        array: API_FIELD_NAMES.PREVIOUS_EMPLOYMENT_OBJ,
      },
      {
        id: 8,
        label: FIELD_LABELS.CTC_IN_LPA,
        type: INPUT_TYPES.NUMERIC,
        name: API_FIELD_NAMES.CTC_IN_LPA,
        required: true,
        array: API_FIELD_NAMES.PREVIOUS_EMPLOYMENT_OBJ,
      },
    ],
  },
  [API_FIELD_NAMES.MY_TRAINING]: [
    {
      id: 1,
      label: FIELD_LABELS.IT_SETUP_TRAINING,
      type: INPUT_TYPES.TEXT,
      name: API_FIELD_NAMES.IT_SETUP_TRAINING,
      required: true,
      select: true,
      selectionItems: completionStatusType,
    },
    {
      id: 2,
      label: FIELD_LABELS.SECURITY_TRAINING,
      type: INPUT_TYPES.TEXT,
      name: API_FIELD_NAMES.SECURITY_TRAINING,
      required: true,
      select: true,
      selectionItems: completionStatusType,
    },
    {
      id: 3,
      label: FIELD_LABELS.FIRE_DRILL,
      type: INPUT_TYPES.TEXT,
      name: API_FIELD_NAMES.FIRE_DRILL,
      required: true,
      select: true,
      selectionItems: completionStatusType,
    },
    {
      id: 4,
      label: FIELD_LABELS.HEALTH_AND_SAFETY,
      type: INPUT_TYPES.TEXT,
      name: API_FIELD_NAMES.HEALTH_AND_SAFETY,
      select: true,
      selectionItems: completionStatusType,
    },
    {
      id: 5,
      label: FIELD_LABELS.POSH,
      type: INPUT_TYPES.TEXT,
      name: API_FIELD_NAMES.POSH,
      required: true,
      select: true,
      selectionItems: completionStatusType,
    },
    {
      id: 6,
      label: FIELD_LABELS.OTHERS,
      type: INPUT_TYPES.TEXT,
      name: API_FIELD_NAMES.OTHERS,
      required: false,
    },
  ],
  [API_FIELD_NAMES.ACCOUNT_DETAILS]: {
    [API_FIELD_NAMES.BANK_DETAILS]: [
      {
        id: 1,
        label: FIELD_LABELS.BANK_NAME,
        type: INPUT_TYPES.TEXT,
        name: API_FIELD_NAMES.BANK_NAME,
        required: true,
      },
      {
        id: 2,
        label: FIELD_LABELS.BANK_ACCOUNT_NO,
        type: INPUT_TYPES.TEXT,
        name: API_FIELD_NAMES.BANK_ACCOUNT_NO,
        required: true,
        maxLength: 18,
      },
      {
        id: 3,
        label: FIELD_LABELS.BRANCH,
        type: INPUT_TYPES.TEXT,
        name: API_FIELD_NAMES.BRANCH,
        required: true,
      },
      {
        id: 4,
        label: FIELD_LABELS.IFSC,
        type: INPUT_TYPES.TEXT,
        name: API_FIELD_NAMES.IFSC,
        required: true,
        maxLength: 11,
      },
    ],
    [API_FIELD_NAMES.PF_ACCOUNT_DETIALS]: [
      {
        id: 1,
        label: FIELD_LABELS.PF_NUMBER,
        type: INPUT_TYPES.TEXT,
        name: API_FIELD_NAMES.PF_NUMBER,
        required: true,
        maxLength: 22,
      },
      {
        id: 2,
        label: FIELD_LABELS.UAN,
        type: INPUT_TYPES.TEXT,
        name: API_FIELD_NAMES.UAN,
        required: true,
      },
      {
        id: 3,
        label: FIELD_LABELS.PF_DOJ,
        type: INPUT_TYPES.DATE,
        name: API_FIELD_NAMES.PF_DOJ,
        required: true,
      },
    ],
  },
};

const dateValidation = date()
  .typeError("Invalid date")
  .required("Date is required");

export const editPageValidationSchema = object().shape({
  profilePhoto: string(),
  employeeId: string()
    .min(3, ERROR_MESSAGES.employeeId.min)
    .required(ERROR_MESSAGES.employeeId.required),
  name: string().required(ERROR_MESSAGES.name.required),
  contactNumber: string()
    .matches(/^[6-9]\d{9}$/, ERROR_MESSAGES.contactNumber.matches)
    .required(ERROR_MESSAGES.contactNumber.required),
  role: string()
    .test(
      "is-not-select",
      ERROR_MESSAGES.role["is-not-select"],
      (value) => value !== DEFAULT_SELECT
    )
    .required(ERROR_MESSAGES.role.required),
  email: string()
    .email(ERROR_MESSAGES.email.email)
    .required(ERROR_MESSAGES.email.required),
  dateOfBirth: string().required(ERROR_MESSAGES.dateOfBirth.required),
  fatherName: string().required(ERROR_MESSAGES.fatherName.required),
  motherName: string().required(ERROR_MESSAGES.motherName.required),
  maritalStatus: string()
    .test(
      "is-not-select",
      ERROR_MESSAGES.maritalStatus["is-not-select"],
      (value) => value !== DEFAULT_SELECT
    )
    .required(ERROR_MESSAGES.maritalStatus.required),
  bloodGroup: string()
    .test(
      "is-not-select",
      ERROR_MESSAGES.bloodGroup["is-not-select"],
      (value) => value !== DEFAULT_SELECT
    )
    .required(ERROR_MESSAGES.bloodGroup.required),
  emergencyContactName: string().required(
    ERROR_MESSAGES.emergencyContactName.required
  ),
  emergencyContactNumber: string()
    .matches(/^[6-9]\d{9}$/, ERROR_MESSAGES.emergencyContactNumber.matches)
    .required(ERROR_MESSAGES.emergencyContactNumber.required),
  nationality: string().required(ERROR_MESSAGES.nationality.required),
  aadhaarNo: string()
    .matches(/^\d{12}$/, ERROR_MESSAGES.aadhaarNo.matches)
    .required(ERROR_MESSAGES.aadhaarNo.required),
  panCardNo: string()
    .matches(/^([a-zA-Z]{5}\d{4}[a-zA-Z])$/, ERROR_MESSAGES.panCardNo.matches)
    .required(ERROR_MESSAGES.panCardNo.required),
  passportNo: string().notRequired(),
  addressLineOne: string().required(ERROR_MESSAGES.addressLineOne.required),
  zipCode: string()
    .matches(/^\d{6}$/, ERROR_MESSAGES.zipCode.matches)
    .required(ERROR_MESSAGES.zipCode.required),
  skillSet: string().notRequired(),
  itSetUpTraining: string()
    .test(
      "is-not-select",
      ERROR_MESSAGES.itSetUpTraining["is-not-select"],
      (value) => value !== DEFAULT_SELECT
    )
    .required(ERROR_MESSAGES.itSetUpTraining.required),
  securityTraining: string()
    .test(
      "is-not-select",
      ERROR_MESSAGES.securityTraining["is-not-select"],
      (value) => value !== DEFAULT_SELECT
    )
    .required(ERROR_MESSAGES.securityTraining.required),
  fireDrill: string()
    .test(
      "is-not-select",
      ERROR_MESSAGES.fireDrill["is-not-select"],
      (value) => value !== DEFAULT_SELECT
    )
    .required(ERROR_MESSAGES.fireDrill.required),
  healthAndSafety: string()
    .test(
      "is-not-select",
      ERROR_MESSAGES.healthAndSafety["is-not-select"],
      (value) => value !== DEFAULT_SELECT
    )
    .required(ERROR_MESSAGES.healthAndSafety.required),
  posh: string()
    .test(
      "is-not-select",
      ERROR_MESSAGES.posh["is-not-select"],
      (value) => value !== DEFAULT_SELECT
    )
    .required(ERROR_MESSAGES.posh.required),
  others: string().required(ERROR_MESSAGES.others.required),
  bankName: string().required(ERROR_MESSAGES.bankName.required),
  bankAccountNo: string()
    .matches(/^\d{1,20}$/, ERROR_MESSAGES.bankAccountNo.matches)
    .required(ERROR_MESSAGES.bankAccountNo.required),
  branch: string().required(ERROR_MESSAGES.branch.required),
  ifsc: string().required(ERROR_MESSAGES.ifsc.required),
  pfNumber: string().required(ERROR_MESSAGES.pfNumber.required),
  uan: string().required(ERROR_MESSAGES.uan.required),
  pfJoining: date().required(ERROR_MESSAGES.pfJoining.required),

  education: array().of(
    object().shape({
      level: string()
        .test(
          "is-not-select",
          ERROR_MESSAGES.education.level["is-not-select"],
          (value) => value !== DEFAULT_SELECT
        )
        .required(ERROR_MESSAGES.education.level.required),
      university: string().required(
        ERROR_MESSAGES.education.university.required
      ),
      fieldOfStudy: string().required(
        ERROR_MESSAGES.education.fieldOfStudy.required
      ),
      startDate: dateValidation.required(
        ERROR_MESSAGES.education.startDate.required
      ),
      endDate: date()
        .required(ERROR_MESSAGES.education.endDate.required)
        .min(ref("startDate"), ERROR_MESSAGES.education.endDate.min),
      percentage: number()
        .required(ERROR_MESSAGES.education.percentage.required)
        .typeError(ERROR_MESSAGES.education.percentage.typeError),
    })
  ),

  certification: array().of(
    object().shape({
      certificationName: string().notRequired(),
      institute: string().notRequired(),
    })
  ),

  previousEmployments: array().of(
    object().shape({
      designation: string().required(
        ERROR_MESSAGES.previousEmployments.designation.required
      ),
      employmentType: string()
        .test(
          "is-not-select",
          ERROR_MESSAGES.previousEmployments.employmentType["is-not-select"],
          (value) => value !== DEFAULT_SELECT
        )
        .required(ERROR_MESSAGES.previousEmployments.employmentType.required),
      totalRelevantExperience: string().required(
        ERROR_MESSAGES.previousEmployments.totalRelevantExperience.required
      ),
      companyName: string().required(
        ERROR_MESSAGES.previousEmployments.companyName.required
      ),
      ctcInLPA: number()
        .required(ERROR_MESSAGES.previousEmployments.ctcInLPA.required)
        .typeError(ERROR_MESSAGES.previousEmployments.ctcInLPA.typeError),
    })
  ),

  currentEmployment: array().of(
    object().shape({
      designation: string().required(
        ERROR_MESSAGES.currentEmployment.designation.required
      ),
      employmentType: string()
        .test(
          "is-not-select",
          ERROR_MESSAGES.currentEmployment.employmentType["is-not-select"],
          (value) => value !== DEFAULT_SELECT
        )
        .required(ERROR_MESSAGES.currentEmployment.employmentType.required),
      client: string()
        .test(
          "is-not-select",
          ERROR_MESSAGES.currentEmployment.client["is-not-select"],
          (value) => value !== DEFAULT_SELECT
        )
        .required(ERROR_MESSAGES.currentEmployment.client.required),
      tagging: string()
        .test(
          "is-not-select",
          ERROR_MESSAGES.currentEmployment.tagging["is-not-select"],
          (value) => value !== DEFAULT_SELECT
        )
        .required(ERROR_MESSAGES.currentEmployment.tagging.required),
      status: string()
        .test(
          "is-not-select",
          ERROR_MESSAGES.currentEmployment.status["is-not-select"],
          (value) => value !== DEFAULT_SELECT
        )
        .required(ERROR_MESSAGES.currentEmployment.status.required),
      myProject: string()
        .test(
          "is-not-select",
          ERROR_MESSAGES.currentEmployment.myProject["is-not-select"],
          (value) => value !== DEFAULT_SELECT
        )
        .required(ERROR_MESSAGES.currentEmployment.myProject.required),
      supervisor: string().required(
        ERROR_MESSAGES.currentEmployment.supervisor.required
      ),
      startDate: dateValidation.required(
        ERROR_MESSAGES.currentEmployment.startDate.required
      ),
      endDate: date()
      .nullable()
      .notRequired()
      .min(ref("startDate"), ERROR_MESSAGES.currentEmployment.endDate.min),
    })
  ),
});

const editPageInitialValues = {
  profilePhoto:
    "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQpJZ7XQf4_1miq-ElTKKxAnZD_ugDw2RVSsA&s",
  employeeId: "",
  name: "",
  contactNumber: "",
  role: DEFAULT_SELECT,
  email: "",
  dateOfBirth: "",
  fatherName: "",
  motherName: "",
  maritalStatus: DEFAULT_SELECT,
  spouse: "",
  noOfChildren: 0,
  bloodGroup: DEFAULT_SELECT,
  emergencyContactName: "",
  emergencyContactNumber: "",
  nationality: "",
  aadhaarNo: "",
  panCardNo: "",
  passportNo: "",
  addressLineOne: "",
  zipCode: "",
  skillSet: "",
  itSetUpTraining: DEFAULT_SELECT,
  securityTraining: DEFAULT_SELECT,
  fireDrill: DEFAULT_SELECT,
  healthAndSafety: DEFAULT_SELECT,
  posh: DEFAULT_SELECT,
  others: "",
  bankName: "",
  bankAccountNo: "",
  branch: "",
  ifsc: "",
  pfNumber: "",
  uan: "",
  pfJoining: "",
  education: [
    {
      level: DEFAULT_SELECT,
      university: "",
      fieldOfStudy: "",
      startDate: "",
      endDate: "",
      percentage: "",
    },
  ],

  certification: [{ certificationName: "", institute: "" }],
  previousEmployments: [
    {
      designation: "",
      employmentType: DEFAULT_SELECT,
      totalRelevantExperience: "",
      companyName: "",
      companyLocation: "",
      workMode: DEFAULT_SELECT,
      bandLevel: DEFAULT_SELECT,
      ctcInLPA: "",
    },
  ],
  currentEmployment: [
    {
      designation: "",
      employmentType: DEFAULT_SELECT,
      client: DEFAULT_SELECT,
      tagging: DEFAULT_SELECT,
      status: DEFAULT_SELECT,
      myProject: DEFAULT_SELECT,
      supervisor: "",
      startDate: "",
      endDate: "",
    },
  ],
};

export { editPageInputs, editPageInitialValues };
