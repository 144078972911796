import { useState, useEffect } from "react";
import LocalStorage from "../utils/StorsgeUtil/LocalStorage";

const useUserRole = () => {
  const [userRole, setUserRole] = useState(null);

  useEffect(() => {
    const userData = JSON.parse(LocalStorage.getUserData());

    const role = userData?.role;
    setUserRole(role);
  }, []);

  return userRole;
};

export default useUserRole;
