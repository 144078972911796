import BreadCrumb from "./BreadCrumb";
import React, { useRef, useState } from "react";
import { USER } from "../../lib/api";
import {
  API_FIELD_NAMES,
  BREADCRUMB_STATUS,
  FIELD_HEADING,
  FIELD_HEADING_DESCRIPTION,
} from "../../utils/constants/keywords";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../utils/constants/routes";
import {
  editPageInitialValues,
  editPageInputs,
  editPageValidationSchema,
} from "./editPageInputs";
import { postHelper } from "../../utils/axios/apiServices";
import {
  ERROR_MESSAGES,
  SUCCESS_MESSAGES,
} from "../../utils/constants/messages";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import FormActionButton from "./FormActionButton";
import ProfilePhoto from "./ProfilePhoto";
import SectionHeader from "./SectionHeader";
import EmployeeInputFields from "./EmployeeInputFields";
import EmployeeArrayInputFields from "./EmployeeArrayInputFields";

function EmployeeForm({ breadCrumbSteps }) {
  const [addDetail, setAddDetail] = useState({
    education: [{ id: 1, detail: 0 }],
    certification: [{ id: 2, detail: 0 }],
    previousEmployments: [{ id: 3, detail: 0 }],
    currentEmployment: [{ id: 4, detail: 0 }],
  });
  const navigate = useNavigate();

  // BreadCrumb steps
  const [steps, setSteps] = useState(breadCrumbSteps);
  const fileInputRef = useRef(null);

  const handleBreadcrumbNext = () => {
    setSteps((prevSteps) => {
      const newSteps = [...prevSteps];
      const currentIndex = newSteps.findIndex(
        (step) => step.status === BREADCRUMB_STATUS.CURRENT
      );
      if (currentIndex !== -1 && currentIndex < newSteps.length - 1) {
        newSteps[currentIndex].status = BREADCRUMB_STATUS.COMPLETE;
        newSteps[currentIndex + 1].status = BREADCRUMB_STATUS.CURRENT;
      }
      return newSteps;
    });
  };

  const handleBreadcrumbBack = () => {
    setSteps((prevSteps) => {
      const newSteps = [...prevSteps];
      const currentIndex = newSteps.findIndex(
        (step) => step.status === BREADCRUMB_STATUS.CURRENT
      );

      if (currentIndex !== -1 && currentIndex > 0) {
        newSteps[currentIndex - 1].status = BREADCRUMB_STATUS.CURRENT;
        newSteps[currentIndex].status = BREADCRUMB_STATUS.UPCOMMING;
      }
      return newSteps;
    });
  };

  // Add Button
  const handleAddDetail = (type) => {
    setAddDetail((prevData) => {
      const maxItems = (type === API_FIELD_NAMES.EDUCATION_OBJ && 5) || 3;
      if (prevData[type].length < maxItems) {
        return {
          ...prevData,
          [type]: [
            ...prevData[type],
            {
              id: prevData[type][prevData[type].length - 1].id + 1,
              detail: prevData[type][prevData[type].length - 1].detail + 1,
            },
          ],
        };
      }
      return prevData;
    });
  };

  const isProfileImgButtonClick = () => {
    fileInputRef.current.click();
  };

  const formik = useFormik({
    initialValues: editPageInitialValues,
    validationSchema: editPageValidationSchema,
    onSubmit: async (values) => {
      console.log(values);
      const createURL = `${USER.CREATE_USER}`;
      try {
        const response = await postHelper(
          createURL,
          values,
          SUCCESS_MESSAGES.PROFILE_CREATE,
          ERROR_MESSAGES.TRY_AGAIN
        );
        if (response) {
          navigate(ROUTES.EMPLOYEES);
        }
      } catch (error) {
        toast.error(ERROR_MESSAGES.TRY_AGAIN);
      }
    },
  });
  const handleNestedChange = (array, index, name, value) => {
    formik.setFieldValue(`${array}[${index}][${name}]`, value);
  };

  return (
    <div className="space-y-4">
      <BreadCrumb steps={steps} />
      <div className=" w-full relative space-y-12 bg-white rounded-lg px-5 lg:px-20 pt-7 py-24 mb-16">
        <form onSubmit={formik.handleSubmit}>
          <div className="">
            {/* Profile */}
            {steps[0].status === BREADCRUMB_STATUS.CURRENT && (
              <>
                <SectionHeader
                  heading={FIELD_HEADING.PROFILE}
                  description={FIELD_HEADING_DESCRIPTION.PROFILE}
                />
                <ProfilePhoto
                  photoData={formik.values?.profilePhoto}
                  isProfileImgButtonClick={isProfileImgButtonClick}
                  fileInputRef={fileInputRef}
                  hanleChange={formik.handleChange}
                />
                <EmployeeInputFields
                  inputs={editPageInputs.Profile}
                  formik={formik}
                />
              </>
            )}

            {/* Personal Detail */}
            {steps[1].status === BREADCRUMB_STATUS.CURRENT && (
              <EmployeeInputFields
                inputs={editPageInputs.Personal}
                formik={formik}
                heading={FIELD_HEADING.PERSONAL_DETAILS}
                description={FIELD_HEADING_DESCRIPTION.PERSONAL_DETAILS}
              />
            )}

            {/* Education Detail */}
            {steps[2].status === BREADCRUMB_STATUS.CURRENT && (
              <>
                {/* Education Section*/}
                <EmployeeArrayInputFields
                  heading={FIELD_HEADING.EDUCATION_DETAILS}
                  description={FIELD_HEADING_DESCRIPTION.EDUCATION_DETAILS}
                  inputs={
                    editPageInputs.Education[
                      API_FIELD_NAMES.SPECIALIZATION_OF_STUDY
                    ]
                  }
                  formik={formik}
                  handleNestedChange={handleNestedChange}
                  addSection={addDetail.education}
                  handleAddDetail={() =>
                    handleAddDetail(API_FIELD_NAMES.EDUCATION_OBJ)
                  }
                />
                <hr className="my-12" />
                {/* Certificaition section */}
                <EmployeeArrayInputFields
                  heading={FIELD_HEADING.CERTIFICATION_DETAILS}
                  description={FIELD_HEADING_DESCRIPTION.CERTIFICATION_DETAILS}
                  inputs={
                    editPageInputs.Education[
                      API_FIELD_NAMES.CERTIFICATION_DETAILS
                    ]
                  }
                  formik={formik}
                  handleNestedChange={handleNestedChange}
                  addSection={addDetail.certification}
                  handleAddDetail={() =>
                    handleAddDetail(API_FIELD_NAMES.CERTIFICATION_OBJ)
                  }
                />
                <hr className="my-12" />
                {/* Skillset section */}
                <EmployeeInputFields
                  inputs={
                    editPageInputs.Education[API_FIELD_NAMES.SKILLSET_OBJ]
                  }
                  formik={formik}
                  heading={FIELD_HEADING.SKILLSET}
                  description={FIELD_HEADING_DESCRIPTION.SKILLSET}
                />
              </>
            )}

            {/* Employment Detail */}
            {steps[3].status === BREADCRUMB_STATUS.CURRENT && (
              <>
                <div className="flex flex-col">
                  <h2 className="text-lg font-bold leading-7 text-gray-900">
                    {FIELD_HEADING.EMPLOYMENT_DETAILS}
                  </h2>
                  <p className="mt-6  text-lg font-bold leading-7 text-gray-900">
                    {FIELD_HEADING_DESCRIPTION.EMPLOYMENT_DETAILS}
                  </p>
                </div>
                {/* Current Employment section */}
                <EmployeeArrayInputFields
                  inputs={
                    editPageInputs[API_FIELD_NAMES.EMPLOYMENT_DETAIL]
                      .currentEmployment
                  }
                  formik={formik}
                  handleNestedChange={handleNestedChange}
                  addSection={addDetail.currentEmployment}
                  hideAdd={true}
                />
                <hr className="mb-12" />
                {/* Previous Employment section */}
                <EmployeeArrayInputFields
                  heading={FIELD_HEADING.PREVIOUS_EMPLOYMENT_DETAILS}
                  description=""
                  inputs={
                    editPageInputs?.[API_FIELD_NAMES.EMPLOYMENT_DETAIL]
                      ?.previousEmployments
                  }
                  formik={formik}
                  handleNestedChange={handleNestedChange}
                  addSection={addDetail.previousEmployments}
                  handleAddDetail={() =>
                    handleAddDetail(API_FIELD_NAMES.PREVIOUS_EMPLOYMENT_OBJ)
                  }
                />
              </>
            )}

            {/* Employee Training */}
            {steps[4].status === BREADCRUMB_STATUS.CURRENT && (
              <EmployeeInputFields
                inputs={editPageInputs[API_FIELD_NAMES.MY_TRAINING]}
                formik={formik}
                heading={FIELD_HEADING.MY_TRAINING}
                description={FIELD_HEADING_DESCRIPTION.MY_TRAINING}
              />
            )}

            {/* Bank Details */}
            {steps[5].status === BREADCRUMB_STATUS.CURRENT && (
              <>
                <div className="my-12">
                  {/* Bank Details section */}
                  <EmployeeInputFields
                    inputs={
                      editPageInputs[API_FIELD_NAMES.ACCOUNT_DETAILS][
                        API_FIELD_NAMES.BANK_DETAILS
                      ]
                    }
                    formik={formik}
                    heading={FIELD_HEADING.BANK_DETAILS}
                    description={FIELD_HEADING_DESCRIPTION.BANK_DETAILS}
                  />
                </div>
                <hr className="mb-12" />
                {/* PF Details section */}
                <EmployeeInputFields
                  inputs={
                    editPageInputs[API_FIELD_NAMES.ACCOUNT_DETAILS][
                      API_FIELD_NAMES.PF_ACCOUNT_DETIALS
                    ]
                  }
                  formik={formik}
                  heading={FIELD_HEADING.PF_ACCOUNT_DETAILS}
                  description={FIELD_HEADING_DESCRIPTION.PF_ACCOUNT_DETAILS}
                />
              </>
            )}
          </div>
          <FormActionButton
            steps={steps}
            handleBreadcrumbBack={handleBreadcrumbBack}
            handleBreadcrumbNext={handleBreadcrumbNext}
          />
        </form>
      </div>
    </div>
  );
}

export default React.memo(EmployeeForm);
