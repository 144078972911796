import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Dropdown from "../ReusableComponents/Dropdown";
import {
  PRIORITY_OPTIONS,
  DEPARTMENT_OPTIONS,
} from "../../utils/constants/dropdown";
import { postHelper } from "../../utils/axios/apiServices";
import { toast } from "react-toastify";
import { ISSUES } from "../../lib/api";

const CreateIssueForm = ({ onCancel, onSubmit }) => {
  const [showAssignDropdown, setShowAssignDropdown] = useState(false);
  const [showPriorityDropdown, setShowPriorityDropdown] = useState(false);
  const [file, setFile] = useState(null);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
  };

  const formik = useFormik({
    initialValues: {
      subject: "",
      issueDescription: "",
      priority: "",
      status: "Open",
      assignedTo: {
        department: "",
      },
    },
    validationSchema: Yup.object({
      subject: Yup.string()
        .max(50, "Must be 50 characters or less")
        .required("Required"),
      issueDescription: Yup.string()
        .max(200, "Must be 200 characters or less")
        .required("Required"),
      priority: Yup.string()
        .oneOf(PRIORITY_OPTIONS, "Invalid priority")
        .required("Required"),
      assignedTo: Yup.object({
        department: Yup.string().required("Required"),
        name: Yup.string(),
        emailId: Yup.string().email("Invalid email format"),
      }),
    }),
    onSubmit: async (values, { resetForm }) => {
      try {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("subject", values.subject);
        formData.append("issueDescription", values.issueDescription);
        formData.append("priority", values.priority);
        formData.append("status", values.status);
        formData.append("assignedTo.department", values.assignedTo.department);

        const response = await postHelper(
          ISSUES.CREATE_ISSUE,
          formData,
          "Issue created successfully",
          "Failed to create issue"
        );

        if (response && response.data && response.data.documentUrl) {
          resetForm();
          setFile(null);
          onSubmit();
        }
      } catch (error) {
        console.error("Error creating issue:", error);
        // Handle network errors or other exceptions
        toast.error("Failed to create issue");
      }
    },
  });

  return (
    <form
      onSubmit={formik.handleSubmit}
      className="px-5 mt-6 w-full max-md:max-w-full"
    >
      <div className="relative w-full p-4 bg-white mt-4 rounded-md shadow-md">
        <p className="my-5 font-bold">Create Issues</p>
        <FormField
          label="Subject"
          name="subject"
          placeholder="Subject"
          value={formik.values.subject}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.subject && formik.errors.subject}
        />
        <FormTextArea
          label="Issue Description"
          name="issueDescription"
          placeholder="Write a description..."
          value={formik.values.issueDescription}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={
            formik.touched.issueDescription && formik.errors.issueDescription
          }
        />
        <Attachments
          file={file}
          onFileChange={handleFileChange}
          setFile={setFile}
        />
        <div className="flex justify-between items-end mt-4">
          <div className="flex gap-4">
            <DropdownField
              label="Assign"
              showDropdown={showAssignDropdown}
              setShowDropdown={setShowAssignDropdown}
              options={DEPARTMENT_OPTIONS}
              selectedOption={formik.values.assignedTo.department}
              onSelect={(option) => {
                formik.setFieldValue(
                  "assignedTo.department",
                  option === "UnAssigned" ? "" : option
                );
                setShowAssignDropdown(false);
              }}
              error={
                formik.touched.assignedTo?.department &&
                formik.errors.assignedTo?.department
              }
            />
            <DropdownField
              label="Priority"
              showDropdown={showPriorityDropdown}
              setShowDropdown={setShowPriorityDropdown}
              options={PRIORITY_OPTIONS}
              selectedOption={formik.values.priority}
              onSelect={(option) => {
                formik.setFieldValue("priority", option);
                setShowPriorityDropdown(false);
              }}
              error={formik.touched.priority && formik.errors.priority}
            />
          </div>
          <div className="flex gap-2">
            <button
              type="button"
              className="px-4 py-2 bg-white text-black border font-medium rounded-md"
              onClick={onCancel}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-blue-700 font-medium text-white rounded-md"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

const FormField = ({
  label,
  name,
  placeholder,
  value,
  onChange,
  onBlur,
  error,
}) => (
  <div className="mb-4">
    <input
      type="text"
      name={name}
      placeholder={placeholder}
      className="w-full p-2 border rounded-md"
      onChange={onChange}
      onBlur={onBlur}
      value={value}
    />
    {error && <div className="text-red-600 text-sm p-3">{error}</div>}
  </div>
);

const FormTextArea = ({
  label,
  name,
  placeholder,
  value,
  onChange,
  onBlur,
  error,
}) => (
  <div className="relative w-full mb-4 border rounded-md">
    <textarea
      name={name}
      placeholder={placeholder}
      className="w-full h-40 p-2"
      onChange={onChange}
      onBlur={onBlur}
      value={value}
    />
    {error && <div className="text-red-600 text-sm p-3">{error}</div>}
  </div>
);

const Attachments = ({ file, onFileChange, setFile }) => (
  <div className="flex justify-between items-center mt-2">
    <div>
      {file && (
        <div className="flex items-center mr-2 mb-2 bg-gray-100 p-1 rounded-md">
          <span className="mr-2">{file.name}</span>
          <button
            type="button"
            className="text-red-600"
            onClick={() => setFile(null)}
          >
            X
          </button>
        </div>
      )}
    </div>
    <label className="flex items-center cursor-pointer px-2 py-1 rounded-md">
      Attach a file
      <input type="file" className="hidden" onChange={onFileChange} />
    </label>
  </div>
);

const DropdownField = ({
  label,
  showDropdown,
  setShowDropdown,
  options,
  selectedOption,
  onSelect,
  error,
}) => (
  <div className="relative flex gap-4 text-sm font-semibold leading-5 text-center text-gray-500">
    <button
      type="button"
      className="relative flex flex-col justify-center px-3.5 py-3.5 rounded-full bg-zinc-100"
      onClick={() => setShowDropdown(!showDropdown)}
    >
      <div className="flex gap-0.5">{selectedOption || label}</div>
      {showDropdown && <Dropdown options={options} onSelect={onSelect} />}
    </button>
    {error && <div className="text-red-600 text-sm">{error}</div>}
  </div>
);

export default CreateIssueForm;
