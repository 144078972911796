import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ROUTES } from "../../../utils/constants/routes";
import { ArrowLeftIcon } from "@heroicons/react/24/outline";
import Tabs from "./Tabs";
import {
  ACTION_BUTTONS,
  API_FIELD_NAMES,
  FIELD_HEADING,
} from "../../../utils/constants/keywords";
import EmployeeHeader from "../../ReusableComponents/EmployeeHeader";
import EmployeeDetailSection from "./EmployeeDetailSection";

const tabsObj = [
  {
    id: 1,
    fieldName: FIELD_HEADING.PROFILE,
    name: [API_FIELD_NAMES.PROFILE],
    current: true,
  },
  {
    id: 2,
    fieldName: FIELD_HEADING.PERSONAL_DETAILS,
    name: [API_FIELD_NAMES.PERSONAL],
    current: false,
  },
  {
    id: 3,
    fieldName: FIELD_HEADING.EDUCATION_DETAILS,
    name: [
      API_FIELD_NAMES.EDUCATION_OBJ,
      API_FIELD_NAMES.CERTIFICATION_OBJ,
      API_FIELD_NAMES.SKILLSET_OBJ,
    ],
    current: false,
  },
  {
    id: 4,
    fieldName: FIELD_HEADING.EMPLOYMENT_DETAILS,
    name: [
      API_FIELD_NAMES.CURRENT_EMPLOYMENT_OBJ,
      API_FIELD_NAMES.PREVIOUS_EMPLOYMENT_OBJ,
    ],
    current: false,
  },
  {
    id: 5,
    fieldName: FIELD_HEADING.MY_TRAINING,
    name: [API_FIELD_NAMES.MY_TRAINING],
    current: false,
  },
  {
    id: 6,
    fieldName: FIELD_HEADING.ACCOUNT_DETAILS,
    name: [API_FIELD_NAMES.BANK_DETAILS, API_FIELD_NAMES.PF_ACCOUNT_DETIALS],
    current: false,
  },
];
const SeniorManagementEmployeeView = () => {
  const [tabs, setTabs] = useState(tabsObj);
  const navigate = useNavigate();
  const handleBack = () => {
    navigate(ROUTES.SENIOR_MANAGEMENT);
  };
  const location = useLocation();
  const  employee  = location.state?.employee;
  const [tabSelection, settabSelection] = useState([API_FIELD_NAMES.PROFILE]);

  const handleTabs = (selectedTabData) => {
    const updatedTabs = tabs.map((tab) => ({
      ...tab,
      current: tab.id === selectedTabData.id,
    }));
    setTabs(updatedTabs);
    settabSelection(selectedTabData.name);
  };
  return (
    <div className="px-5  md:px-20 pb-10">
      <div
        className=" flex gap-x-3  mt-6 mb-6 cursor-pointer"
        onClick={handleBack}
      >
        <ArrowLeftIcon className="h-6 w-6 " />
        <div className="font-medium text-base ">{ACTION_BUTTONS.BACK}</div>
      </div>
      <div className=" bg-white ">
        <EmployeeHeader empName={employee?.name} empId={employee?.employeeId} />
        <div className="flex flex-col sm:flex-row py-10">
          <Tabs handleTabs={handleTabs} tabsList={tabs} />
          <EmployeeDetailSection
            employee={employee}
            sectionTypes={tabSelection}
          />
        </div>
      </div>
    </div>
  );
};

export default SeniorManagementEmployeeView;
