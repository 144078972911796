import React, { useState } from "react";
import { useParams } from "react-router-dom";
import Header from "../Header";
import { employeeDetailIssues as empissues } from "../../lib/label.js";
import Container from "../ReusableComponents/Container.js";
import useEmployeeIssueData from "../../hooks/useEmployeeIssueData.js";

const MyIssuesDetails = () => {
  const { issueId } = useParams();
  const {
    employeeData: issueDetails,
    loading,
    error,
  } = useEmployeeIssueData(issueId);
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedPriority, setSelectedPriority] = useState(null);

  const handleEditClick = () => {
    setIsEditMode(true);
  };

  const handleCancelClick = () => {
    setIsEditMode(false);
    setSelectedPriority(issueDetails?.priority);
  };

  const handleSaveClick = () => {};

  const handleWithdrawClick = () => {};

  const handleCloseIssueClick = () => {};

  const handleCommentSubmit = () => {};

  const formatDate = (date) => {};

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!issueDetails) {
    return <div>No issue details found</div>;
  }

  const {
    assignedTo,
    subject,
    issueDescription,
    priority,
    comments,
    userImage,
    documentUrl,
    name,
  } = issueDetails;

  return (
    <div className="relative flex flex-col items-center pb-14 bg-gray-100">
      <Container>
        <div className="mt-5 mx-5 w-full flex justify-start">
          <div className="flex flex-col">
            <span className="text-xl font-semibold">
              {empissues.empIssuesDetail}
            </span>
            <span className="text-slate-500">
              {empissues.myIssuesSubTitleDetail}
            </span>
          </div>
        </div>

        <section className="px-5 mt-6 w-full max-md:max-w-full">
          <div className="w-full p-5 bg-white rounded-lg shadow-sm">
            <div className="flex flex-col">
              <div className="flex gap-5 justify-between w-full text-sm font-medium max-md:flex-wrap max-md:max-w-full">
                <div className="flex flex-col px-5 leading-6">
                  <div className="text-gray-900">Assign To</div>
                  <div className="mt-2 text-gray-400">
                    {assignedTo ? assignedTo.department : "N/A"}
                  </div>
                </div>
                <div className="flex gap-5 justify-between my-auto">
                  {isEditMode ? (
                    <>
                      <button
                        className="justify-center px-5 py-2.5 text-gray-500 whitespace-nowrap rounded-lg border border-gray-300 border-solid"
                        onClick={handleCancelClick}
                      >
                        Cancel
                      </button>
                      <button
                        className="justify-center px-5 py-2.5 bg-blue-700 text-white rounded-lg"
                        onClick={handleSaveClick}
                      >
                        Save
                      </button>
                    </>
                  ) : (
                    <>
                      <button
                        onClick={handleEditClick}
                        className="bg-transparent border-none p-0"
                      >
                        <img
                          loading="lazy"
                          src="https://cdn.builder.io/api/v1/image/assets/TEMP/6660a39e7a23682f41b1f91511125d9df78abccb46eec81d8ca03afd34d07856?apiKey=d8e91bdc04594ed59f135bc4e59e9ebd&"
                          alt=""
                          className="shrink-0 my-auto w-6 aspect-square cursor-pointer"
                        />
                      </button>
                      <button
                        className="justify-center px-5 py-2.5 text-gray-500 whitespace-nowrap rounded-lg border border-gray-300 border-solid"
                        onClick={handleWithdrawClick}
                      >
                        Withdraw
                      </button>
                      <button
                        className="justify-center px-5 py-2.5 bg-blue-700 text-white rounded-lg"
                        onClick={handleCloseIssueClick}
                      >
                        Close Issue
                      </button>
                    </>
                  )}
                </div>
              </div>
              <div className="flex flex-col self-start px-5 mt-10 text-sm font-medium leading-6">
                <div className="text-gray-900">Subject</div>
                <div className="mt-2 text-gray-400">{subject}</div>
              </div>
              <div className="flex flex-col px-5 mt-10 w-full text-sm font-medium leading-6 max-md:max-w-full">
                <div className="w-full text-gray-900 max-md:max-w-full">
                  Description
                </div>
                <div className="justify-center px-0.5 py-1.5 mt-2 w-full text-gray-400 max-md:pr-5 max-md:max-w-full">
                  {issueDescription}
                </div>
              </div>
              <div className="flex flex-col px-5 mt-10 max-w-full text-sm font-medium w-[130px]">
                <div className="leading-6 text-gray-900">Attachments</div>
                <div className="flex gap-2 mt-2 leading-5 text-gray-400">
                  {documentUrl ? (
                    <>
                      <div>
                        <a
                          href={documentUrl}
                          className="text-blue-700"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Document Link
                        </a>
                      </div>
                      <img
                        loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/4c92b2ef6d4bb6ab9e44734db06cfd948838761b438997a9f921b7b5b0d55bae?apiKey=d8e91bdc04594ed59f135bc4e59e9ebd&"
                        className="shrink-0 w-5 aspect-square"
                      />
                    </>
                  ) : (
                    <div>No attachments</div>
                  )}
                </div>
              </div>
              <div className="flex flex-col self-start px-5 mt-10 text-sm font-medium leading-6 whitespace-nowrap">
                <div className="text-gray-900">Priority</div>
                {isEditMode ? (
                  <div className="mt-2">
                    <select
                      value={selectedPriority || ""}
                      onChange={(e) => setSelectedPriority(e.target.value)}
                      className="block w-full px-3 py-2.5 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:border-blue-500"
                    >
                      <option value="High">High</option>
                      <option value="Medium">Medium</option>
                      <option value="Low">Low</option>
                    </select>
                  </div>
                ) : (
                  <div className="mt-2 text-gray-400">{priority}</div>
                )}
              </div>

              <div className="flex flex-col px-5 mt-10 w-full max-md:max-w-full">
                <div className="w-full text-sm font-medium leading-6 text-gray-900 max-md:max-w-full">
                  Comment
                </div>
                <div className="flex gap-5 justify-between px-5 pt-5 w-full mt-2 text-base border-t border-gray-300 border-solid max-md:max-w-full">
                  <input
                    className="flex-1 justify-center px-5 py-2.5 border rounded-lg border-gray-300 border-solid placeholder:text-zinc-400"
                    placeholder="Enter Comment"
                  />
                  <button onClick={handleCommentSubmit}>
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/31b4820707f31d531e79aba17b893b2732cc32d965e5beaf3f5de423896e2f19?apiKey=d8e91bdc04594ed59f135bc4e59e9ebd&"
                      className="shrink-0 my-auto w-6 aspect-square"
                    />
                  </button>
                </div>
              </div>
              <div className="flex flex-col mt-10 w-full text-base max-md:max-w-full">
                {comments &&
                  comments.map((comment, index) => (
                    <div
                      key={index}
                      className="flex flex-col w-full gap-5 px-5 pt-5 mt-2 border-t border-gray-300 border-solid"
                    >
                      <div className="flex gap-5">
                        <img
                          loading="lazy"
                          src={userImage || "https://via.placeholder.com/150"}
                          className="w-12 h-12 border border-solid rounded-lg border-vista-blue"
                        />
                        <div className="flex flex-col max-w-full">
                          <div className="w-full max-w-full leading-7 text-gray-900">
                            {name}
                          </div>
                          <div className="mt-1 text-gray-400">
                            {comment.comment}
                          </div>
                          <div className="mt-2 text-gray-400">
                            {formatDate(comment.createdAt)}
                          </div>
                        </div>
                      </div>
                      <img
                        loading="lazy"
                        src={documentUrl}
                        className="w-full h-full"
                      />
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </section>
      </Container>
    </div>
  );
};

export default MyIssuesDetails;
