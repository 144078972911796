export const FIELD_LABELS = {
  PHOTO: "Photo",
  EMPLOYEE_ID: "Employee ID",
  EMPLOYEE_NAME: "Employee name",
  DEPARTMENT: "Department",
  CONTACT_NUMBER: "Contact number",
  EMAIL_ADDRESS: "E-mail address",
  DATE_OF_BIRTH: "Date of birth",
  FATHERS_NAME: "Father’s Name",
  MOTHERS_NAME: "Mother’s Name",
  MARITAL_STATUS: "Marital Status",
  SPOUSE: "Spouse Name",
  NO_OF_CHILDREN: "No. of Children",
  BLOOD_GROUP: "Blood Group",
  EMERGENCY_CONTACT_NAME: "Emergency Contact Name",
  EMERGENCY_CONTACT_NUMBER: "Emergency Contact No.",
  NATIONALITY: "Nationality",
  AADHAR_NO: "Aadhar No.",
  PAN_CARD_NO: "Pan Card No.",
  PASSPORT_NO: "Passport No.",
  ADDRESS: "Address",
  ZIP_CODE: "Zip code",
  EDUCATION: "Education",
  UNIVERSITY_BOARD: "University/Board",
  FIELD_OF_STUDY: "Specialization",
  START_DATE: "Start Date",
  END_DATE: "End Date",
  GRADES_PERCENTAGE: "Grades/Percentage",
  DOCUMENT_UPLOAD: "Document upload",
  CERTIFICATION_NAME: "Certification Name",
  INSTITUTE: "Institute",
  DESIGNATION: "Designation",
  EMPLOYMENT_TYPE: "Employment Type",
  CLIENT: "Client",
  TAGGING: "Tagging",
  STATUS: "Status",
  SUPERVISOR: "Supervisor",
  MY_PROJECT: "My Project",
  TOTAL_RELEVANT_EXPERIENCE: "Total Relevant years of experience",
  COMPANY_NAME: "Company Name",
  COMPANY_LOCATION: "Company Location",
  WORK_MODE: "Work mode",
  BAND_LEVEL: "Band Level",
  CTC_IN_LPA: "CTC in LPA",
  IT_SETUP_TRAINING: "IT setup training",
  SECURITY_TRAINING: "Security training",
  FIRE_DRILL: "Fire drill",
  HEALTH_AND_SAFETY: "Health & Safety",
  POSH: "POSH",
  OTHERS: "Others",
  BANK_NAME: "Bank Name",
  BANK_ACCOUNT_NO: "Bank Account No.",
  BRANCH: "Branch",
  IFSC: "IFSC",
  PF_NUMBER: "PF Number",
  UAN: "UAN",
  PF_DOJ: "PF DOJ",
  SKILLSET: "Skill Sets",
};

export const INPUT_TYPES = {
  TEXT: "text",
  TEL: "tel",
  EMAIL: "email",
  DATE: "Date",
  NUMBER: "number",
  NUMERIC: "numeric",
  FILE: "file",
};

export const API_FIELD_NAMES = {
  PROFILE_PHOTO: "profilePhoto",
  EMPLOYEE_ID: "employeeId",
  EMPLOYEE_NAME: "name",
  DEPARTMENT: "role",
  CONTACT_NUMBER: "contactNumber",
  EMAIL_ADDRESS: "email",
  DATE_OF_BIRTH: "dateOfBirth",
  FATHERS_NAME: "fatherName",
  MOTHERS_NAME: "motherName",
  MARITAL_STATUS: "maritalStatus",
  SPOUSE: "spouse",
  NO_OF_CHILDREN: "noOfChildren",
  BLOOD_GROUP: "bloodGroup",
  EMERGENCY_CONTACT_NAME: "emergencyContactName",
  EMERGENCY_CONTACT_NUMBER: "emergencyContactNumber",
  NATIONALITY: "nationality",
  AADHAR_NO: "aadhaarNo",
  PAN_CARD_NO: "panCardNo",
  PASSPORT_NO: "passportNo",
  ADDRESS: "addressLineOne",
  ZIP_CODE: "zipCode",
  EDUCATION: "level",
  UNIVERSITY_BOARD: "university",
  FIELD_OF_STUDY: "fieldOfStudy",
  START_DATE: "startDate",
  END_DATE: "endDate",
  GRADES_PERCENTAGE: "percentage",
  DOCUMENT_UPLOAD: "documentsUpload",
  CERTIFICATION_NAME: "certificationName",
  INSTITUTE: "institute",
  DESIGNATION: "designation",
  EMPLOYMENT_TYPE: "employmentType",
  CLIENT: "client",
  TAGGING: "tagging",
  STATUS: "status",
  SUPERVISOR: "supervisor",
  MY_PROJECT: "myProject",
  TOTAL_RELEVANT_EXPERIENCE: "totalRelevantExperience",
  COMPANY_NAME: "companyName",
  COMPANY_LOCATION: "companyLocation",
  WORK_MODE: "workMode",
  BAND_LEVEL: "bandLevel",
  CTC_IN_LPA: "ctcInLPA",
  IT_SETUP_TRAINING: "itSetUpTraining",
  SECURITY_TRAINING: "securityTraining",
  FIRE_DRILL: "fireDrill",
  HEALTH_AND_SAFETY: "healthAndSafety",
  POSH: "posh",
  OTHERS: "others",
  BANK_NAME: "bankName",
  BANK_ACCOUNT_NO: "bankAccountNo",
  BRANCH: "branch",
  IFSC: "ifsc",
  PF_NUMBER: "pfNumber",
  UAN: "uan",
  PF_DOJ: "pfJoining",
  SKILL_SET: "skillSet",
  SPECIALIZATION_OF_STUDY: "Specialization of Study",
  CERTIFICATION_DETAILS: "Certification Details",
  EMPLOYMENT_DETAIL: "Employment Detail",
  MY_TRAINING: "My Training",
  ACCOUNT_DETAILS: "Account Details",
  BANK_DETAILS: "Bank Details",
  PF_ACCOUNT_DETIALS: "PF Account Details",
  EDUCATION_OBJ: "education",
  CERTIFICATION_OBJ: "certification",
  SKILLSET_OBJ: "Skill Sets",
  CURRENT_EMPLOYMENT_OBJ: "currentEmployment",
  PREVIOUS_EMPLOYMENT_OBJ: "previousEmployments",
  PROFILE: "Profile",
  PERSONAL: "Personal",
};

export const FIELD_HEADING = {
  PROFILE: "Profile",
  PERSONAL_DETAILS: "Personal details",
  EDUCATION_DETAILS: "Education Details",
  CERTIFICATION_DETAILS: "Certification Details",
  SKILLSET: "Skill Sets",
  EMPLOYMENT_DETAILS: "Employment Details",
  PREVIOUS_EMPLOYMENT_DETAILS: "Previous employment details",
  MY_TRAINING: "My Training",
  BANK_DETAILS: "Bank Details",
  PF_ACCOUNT_DETAILS: "PF Account Details",
  ACCOUNT_DETAILS : "Account Details"
};

export const FIELD_HEADING_DESCRIPTION = {
  PROFILE: "Details about the employee",
  PERSONAL_DETAILS: "Personal details about the employee",
  EDUCATION_DETAILS: "Highest education of the employee",
  CERTIFICATION_DETAILS: "Additional certification of the employee",
  SKILLSET: "Skills of the employee",
  EMPLOYMENT_DETAILS: "Current employment details",
  PREVIOUS_EMPLOYMENT_DETAILS: "Previous employment details",
  MY_TRAINING: "Employee training",
  BANK_DETAILS: "Employee bank details",
  PF_ACCOUNT_DETAILS: "Employee PF details",
};

export const DEFAULT_SELECT = "Select";

export const EMPLOYEE_STATUS = {
  ACTIVE: "Active",
  INACTIVE: "Inactive",
};

export const BREADCRUMB_STATUS = {
  CURRENT: "current",
  UPCOMMING: "upcomming",
  COMPLETE: "complete",
  ERROR: "error",
};

export const LANDING_PAGE_TITLE = {
  MY_DATA: "My Data",
  MY_ISSUES: "My Issues",
  MY_TASK: "My Task",
  MY_CARRER: "My Career",
  MY_TIME_SHEET: "My Time Sheet",
  WORK_FORCE_MANAGEMENT: "Work Force Mangement",
};

export const LANDING_PAGE_TITLE_DESCRIPTION = {
  MY_DATA: "Check your data here and get update",
  MY_ISSUES: "Submit and Track Tickets",
  MY_TASK: "Stay Organized with Your To-Do List",
  MY_CARRER: "Track your appraisal and your progression",
  MY_TIME_SHEET: "Track Your Time",
  WORK_FORCE_MANAGEMENT: "Optimize Your Workforce",
};

export const PROJECT_TITLE = "Drishti";

export const LOGIN_PAGE_LABELS = {
  SIGN_IN_TITLE: "Sign In to Dristhi",
  MAIL: "Email",
  PASSWORD: "Password",
  FORGOT_PASSWORD: "Forgot password?",
  SIGN_IN_BUTTON: "Sign in",
  FORGOT_PASSWORD_TITLE: "Enter Email",
  FORGOT_PASSWORD_DESCRIPTION: "Enter your email address to receive a password",
  LOGOUT:'Logout',
  IMG_ALT: "Logo to be added here",
};

export const RESET_PASSWORD_PAGE_LABELS = {
  TITLE: "Reset Password",
  DESCRIPTION:
    "Your new password must be different from your previous passwords.",
  CURRENT_PASSWORD: "Current Password",
  NEW_PASSWORD: "New Password",
  CONFIRM_PASSWORD: "Confirm Password",
};

export const ACTION_BUTTONS = {
  SUBMIT: "Submit",
  CANCEL: "Cancel",
  NEXT: "Next",
  BACK: "Back",
  CHANGE: "Change",
  PREVIOUS:"Previous",
  CREATE : "Create +",
  VIEW: "View Details",
};


export const roles ={
  HR:"HR",
  FINANCE : "FINANCE",
  IT_SUPPORT :"IT SUPPORT",
  EMPLOYEE : "Employee",
  ADMIN :"Admin",
  DELIVERY_EXCELLENCE : "DELIVERY EXCELENCE",
  TAG:"TAG",
  SALES :"SALES",
  MARKETING :"MARKETING",
  SENIOR_MANAGEMENT : "SeniorManagement"
}

export const SENIOR_MANAGEMENT_PAGE_LABELS = {
  EMPLOYEE_NAME : "Employee Name & ID",
  SUPERVISOR : "SUPERVISOR",
  DEPARTMENT : "DEPARTMENT",
  COST_CENTER :"COST CENTER",
  TAGGING : "TAGGING",
  STATUS : "STATUS",
  ACTIONS : "ACTIONS"
}

export const employeeIssuesTitle = {
  empIssuesTitle: "Employees Issues",
  empIssuesSubTitle: "This is the list of employees and thier Issues",
};

export const employeeList = {
  empList: "Employee List",
  empListDesc: " This is the list of the employees and their details",
};