import React, { useEffect, useState } from "react";
import { USER } from "../lib/api";
import { useNavigate, useParams } from "react-router-dom";
import { ArrowLeftIcon } from "@heroicons/react/24/outline";
import { ROUTES } from "../utils/constants/routes";
import {
  editPageInitialValues,
  editPageInputs,
  editPageValidationSchema,
} from "../Components/CreateEmployee/editPageInputs";
import {
  API_FIELD_NAMES,
  DEFAULT_SELECT,
  FIELD_HEADING,
  roles,
} from "../utils/constants/keywords";
import { viewPageInputs } from "../Components/EmployeeProfileManager/viewPageInputs";
import { ERROR_MESSAGES, SUCCESS_MESSAGES } from "../utils/constants/messages";
import { editHelper, getHelper } from "../utils/axios/apiServices";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import EditableUserDetailSection from "../Components/EmployeeProfileManager/EditableUserDetailSection";
import EmployeeHeader from "../Components/ReusableComponents/EmployeeHeader";
import LocalStorage from "../utils/StorsgeUtil/LocalStorage";

const EmployeeProfileManager = () => {
  const navigate = useNavigate();
  const submitButtonStatus = [
    { id: 1, isVisible: false },
    { id: 2, isVisible: false },
    { id: 3, isVisible: false },
    { id: 4, isVisible: false },
    { id: 5, isVisible: false },
    { id: 6, isVisible: false },
    { id: 7, isVisible: false },
    { id: 8, isVisible: false },
    { id: 9, isVisible: false },
    { id: 10, isVisible: false },
  ];
  // editSelection
  const [editSelection, setEditSelection] = useState(submitButtonStatus);
  const { empId } = useParams();
  const handleBack = () => {
    navigate(-1);
  };

  const userData = JSON.parse(LocalStorage.getUserData());
  const role = userData?.role;
  const hideEdit = role === roles.EMPLOYEE;

  const formik = useFormik({
    initialValues: editPageInitialValues,
    validationSchema: editPageValidationSchema,
    onSubmit: async (values) => {
      const removeID = removeFields(values, ["_id", "createdAt", "updatedAt"]);
      formik.setValues(removeID);
      const editURL = `${USER.EDIT_BY_ID}/${empId}`;
      try {
        const response = await editHelper(
          editURL,
          formik.values,
          SUCCESS_MESSAGES.PROFILE_UPDATE,
          ERROR_MESSAGES.TRY_AGAIN
        );
        if (response) {
          setEditSelection(submitButtonStatus);
          formik.setValues({
            ...values,
            ...response,
          });
        }
      } catch (error) {
        toast.error(ERROR_MESSAGES.TRY_AGAIN);
      }
    },
  });
  // Getting employee details
  useEffect(() => {
    const getURL = `${USER.GET_BY_ID}/${empId}`;
    getHelper(getURL, formik.setValues);
  }, [editSelection]);

  // Adding Nested data values in formik
  const handleNestedChange = (array, index, name, value) => {
    formik.setFieldValue(`${array}[${index}][${name}]`, value);
  };

  const handleFormCancel = () => {
    setEditSelection(submitButtonStatus);
  };

  // Switching to edit mode
  const handleEdit = (index) => {
    setEditSelection(submitButtonStatus);
    setEditSelection((prevSelection) =>
      prevSelection.map((item) =>
        item.id === index + 1
          ? { ...item, isVisible: !editSelection[index].isVisible }
          : item
      )
    );
  };

  // Removing the "_id", "createdAt", "updatedAt" from Object
  function removeFields(obj, fieldsToRemove) {
    // Check if the current object is an array
    if (Array.isArray(obj)) {
      obj.forEach((item) => removeFields(item, fieldsToRemove));
    } else if (typeof obj === "object" && obj !== null) {
      // Check if the current object has any of the fields to remove and delete them
      fieldsToRemove.forEach((field) => {
        if (obj.hasOwnProperty(field)) {
          delete obj[field];
        }
      });
      // Recursively check and remove specified fields in nested objects
      for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
          removeFields(obj[key], fieldsToRemove);
        }
      }
    }
    return obj;
  }

  // Adding the new empty Object in exisiting Employee List
  const handleAddDetail = (heading) => {
    const detailMappings = {
      [FIELD_HEADING.EDUCATION_DETAILS]: {
        property: API_FIELD_NAMES.EDUCATION_OBJ,
        newDetail: {
          level: DEFAULT_SELECT,
          university: "",
          fieldOfStudy: "",
          startDate: "",
          endDate: "",
          percentage: "",
        },
      },
      [FIELD_HEADING.CERTIFICATION_DETAILS]: {
        property: API_FIELD_NAMES.CERTIFICATION_OBJ,
        newDetail: {
          certificationName: "",
          institute: "",
        },
      },
      [FIELD_HEADING.PREVIOUS_EMPLOYMENT_DETAILS]: {
        property: API_FIELD_NAMES.PREVIOUS_EMPLOYMENT_OBJ,
        newDetail: {
          designation: "",
          employmentType: DEFAULT_SELECT,
        },
      },
    };

    // Retrieve the property and newDetail from the mappings
    const { property, newDetail } = detailMappings[heading];

    // Update the state based on the property
    formik.setValues((prevData) => ({
      ...prevData,
      [property]: [...prevData[property], newDetail],
    }));
  };

  return (
    <div className="">
      <div
        className="px-5  md:px-20 flex gap-x-3  mt-6 mb-6 cursor-pointer"
        onClick={handleBack}
      >
        <ArrowLeftIcon className="h-6 w-6 " />
        <div className="font-medium text-base ">Employee details</div>
      </div>
      {formik.values !== null && (
        <div className="mx-5 md:mx-20 bg-white mb-24 ">
          <EmployeeHeader empId={empId} empName={formik.values?.name} />
          <form onSubmit={formik.handleSubmit}>
            {Object.keys(viewPageInputs).map((sectionTitle) => (
              <div key={viewPageInputs[sectionTitle].id} className=" ">
                {/* Profile Section */}
                {sectionTitle === API_FIELD_NAMES.PROFILE && (
                  <EditableUserDetailSection
                    sectionTitle={sectionTitle}
                    formik={formik}
                    handleNestedChange={handleNestedChange}
                    handleFormCancel={handleFormCancel}
                    inputs={editPageInputs.Profile}
                    editSelection={editSelection}
                    handleEdit={handleEdit}
                    hideEdit={hideEdit}
                  />
                )}
                {/* Personal Section */}
                {sectionTitle === API_FIELD_NAMES.PERSONAL && (
                  <EditableUserDetailSection
                    sectionTitle={sectionTitle}
                    formik={formik}
                    handleNestedChange={handleNestedChange}
                    handleFormCancel={handleFormCancel}
                    inputs={editPageInputs.Personal}
                    editSelection={editSelection}
                    handleEdit={handleEdit}
                    hideEdit={hideEdit}
                  />
                )}

                {/* Education Section */}
                {sectionTitle === API_FIELD_NAMES.EDUCATION_OBJ && (
                  <EditableUserDetailSection
                    sectionTitle={sectionTitle}
                    formik={formik}
                    handleNestedChange={handleNestedChange}
                    handleFormCancel={handleFormCancel}
                    inputs={
                      editPageInputs.Education[
                        API_FIELD_NAMES.SPECIALIZATION_OF_STUDY
                      ]
                    }
                    editSelection={editSelection}
                    handleEdit={handleEdit}
                    hideEdit={hideEdit}
                  />
                )}

                {/* Certification Section */}
                {sectionTitle === API_FIELD_NAMES.CERTIFICATION_OBJ && (
                  <EditableUserDetailSection
                    sectionTitle={sectionTitle}
                    formik={formik}
                    handleNestedChange={handleNestedChange}
                    handleFormCancel={handleFormCancel}
                    inputs={
                      editPageInputs.Education[
                        API_FIELD_NAMES.CERTIFICATION_DETAILS
                      ]
                    }
                    editSelection={editSelection}
                    handleEdit={handleEdit}
                    hideEdit={hideEdit}
                    handleAddDetail={handleAddDetail}
                  />
                )}

                {/* Skillset section */}
                {sectionTitle === API_FIELD_NAMES.SKILLSET_OBJ && (
                  <EditableUserDetailSection
                    sectionTitle={sectionTitle}
                    formik={formik}
                    handleNestedChange={handleNestedChange}
                    handleFormCancel={handleFormCancel}
                    inputs={
                      editPageInputs.Education[API_FIELD_NAMES.SKILLSET_OBJ]
                    }
                    editSelection={editSelection}
                    handleEdit={handleEdit}
                    hideEdit={hideEdit}
                  />
                )}
                {/* Current Employment section */}
                {sectionTitle === API_FIELD_NAMES.CURRENT_EMPLOYMENT_OBJ && (
                  <EditableUserDetailSection
                    sectionTitle={sectionTitle}
                    formik={formik}
                    handleNestedChange={handleNestedChange}
                    handleFormCancel={handleFormCancel}
                    inputs={
                      editPageInputs[API_FIELD_NAMES.EMPLOYMENT_DETAIL][
                        API_FIELD_NAMES.CURRENT_EMPLOYMENT_OBJ
                      ]
                    }
                    editSelection={editSelection}
                    handleEdit={handleEdit}
                    hideEdit={hideEdit}
                  />
                )}
                {/* Previous Employment section */}
                {sectionTitle === API_FIELD_NAMES.PREVIOUS_EMPLOYMENT_OBJ && (
                  <EditableUserDetailSection
                    sectionTitle={sectionTitle}
                    formik={formik}
                    handleNestedChange={handleNestedChange}
                    handleFormCancel={handleFormCancel}
                    inputs={
                      editPageInputs[API_FIELD_NAMES.EMPLOYMENT_DETAIL][
                        API_FIELD_NAMES.PREVIOUS_EMPLOYMENT_OBJ
                      ]
                    }
                    editSelection={editSelection}
                    handleEdit={handleEdit}
                    hideEdit={hideEdit}
                    handleAddDetail={handleAddDetail}
                  />
                )}

                {/* Bank Details Section */}
                {sectionTitle === API_FIELD_NAMES.BANK_DETAILS && (
                  <EditableUserDetailSection
                    sectionTitle={sectionTitle}
                    formik={formik}
                    handleNestedChange={handleNestedChange}
                    handleFormCancel={handleFormCancel}
                    inputs={
                      editPageInputs[API_FIELD_NAMES.ACCOUNT_DETAILS][
                        API_FIELD_NAMES.BANK_DETAILS
                      ]
                    }
                    editSelection={editSelection}
                    handleEdit={handleEdit}
                    hideEdit={hideEdit}
                  />
                )}
                {/* PF Details Section */}
                {sectionTitle === API_FIELD_NAMES.PF_ACCOUNT_DETIALS && (
                  <EditableUserDetailSection
                    sectionTitle={sectionTitle}
                    formik={formik}
                    handleNestedChange={handleNestedChange}
                    handleFormCancel={handleFormCancel}
                    inputs={
                      editPageInputs[API_FIELD_NAMES.ACCOUNT_DETAILS][
                        API_FIELD_NAMES.PF_ACCOUNT_DETIALS
                      ]
                    }
                    editSelection={editSelection}
                    handleEdit={handleEdit}
                    hideEdit={hideEdit}
                  />
                )}
              </div>
            ))}
          </form>
        </div>
      )}
    </div>
  );
};

export default EmployeeProfileManager;
