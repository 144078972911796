import { UserGroupIcon } from "@heroicons/react/24/outline";
import React from "react";

 function Stats({ taggingCounts, employeesCount }) {
  const stats = [
    {
      id: 1,
      name: "Total No. of employee’s",
      stat: employeesCount || 0,
      icon: UserGroupIcon,
    },
    {
      id: 2,
      name: "Billable",
      stat: taggingCounts?.Billable || 0,
      icon: UserGroupIcon,
    },
    {
      id: 3,
      name: "Bench",
      stat: taggingCounts?.Bench || 0,
      icon: UserGroupIcon,
    },
    {
      id: 4,
      name: "Investment",
      stat: taggingCounts?.Investment || 0,
      icon: UserGroupIcon,
    },
    {
      id: 5,
      name: "NAD",
      stat: taggingCounts?.NAD || 0,
      icon: UserGroupIcon,
    },
    {
      id: 6,
      name: "DNB",
      stat: taggingCounts?.DNB || 0,
      icon: UserGroupIcon,
    },
    {
      id: 7,
      name: "Business support",
      stat: taggingCounts?.["Bussiness Support"] || 0,
      icon: UserGroupIcon,
    },
    {
      id: 8,
      name: "Management",
      stat: taggingCounts?.Management,
      icon: UserGroupIcon,
    },
  ];

  return (
    <div>
      <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
        {stats.map((item) => (
          <div
            key={item.id}
            className="relative overflow-hidden rounded-lg  bg-white px-4 py-2  shadow sm:px-6 sm:py-6"
          >
            <dt>
              <div className="absolute rounded-md bg-indigo-500 p-3">
                <item.icon aria-hidden="true" className="h-6 w-6 text-white" />
              </div>
              <p className="ml-16 truncate text-2xl font-semibold text-gray-900 ">
                {item.stat}
              </p>
            </dt>
            <dd className="ml-16 flex items-baseline ">
              <p className="text-sm font-medium text-gray-500">{item.name}</p>
            </dd>
          </div>
        ))}
      </dl>
    </div>
  );
}

export default React.memo(Stats)