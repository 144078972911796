import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ROUTES } from "../../utils/constants/routes";
import MultiDropdown from "../ReusableComponents/MultiselectDropdown";
import {
  STATUS_OPTIONS,
  PRIORITY_CLASSES,
} from "../../utils/constants/dropdown";

const truncateText = (text, maxLength) =>
  text && text.length > maxLength
    ? text.substring(0, maxLength - 3) + "..."
    : text;

const EmployeeIssueList = ({ issues }) => {
  const [selectedStatuses, setSelectedStatuses] = useState(
    STATUS_OPTIONS.reduce((acc, status) => ({ ...acc, [status]: false }), {})
  );

  const handleStatusChange = (status) => {
    setSelectedStatuses((prevStatuses) => ({
      ...prevStatuses,
      [status]: !prevStatuses[status],
    }));
  };

  const sortedIssues = issues.sort(
    (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
  );

  const formatDate = (createdAt) => {
    const date = new Date(createdAt);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  };

  const filteredIssues = sortedIssues.filter((issue) =>
    Object.values(selectedStatuses).some(Boolean)
      ? selectedStatuses[issue.status]
      : true
  );

  return (
    <section className="px-5 mt-6 w-full max-md:max-w-full">
      <div className="w-full bg-white rounded-lg shadow-sm">
        <div className="table w-full text-sm leading-5 text-gray-500">
          <div className="table-row-group">
            <div className="table-row">
              {[
                { header: "Issues", width: "w-[20%]" },
                { header: "Issue ID", width: "w-[20%]" },
                { header: "Raised Date", width: "w-[8%]" },
                { header: "Assigned", width: "w-[15%]" },
                { header: "Status", width: "w-[10%]" },
                { header: "Priority", width: "w-[10%]" },
                { header: "Closed Date", width: "w-[10%]" },
                { header: "Action", width: "w-[8%]" },
              ].map(({ header, width }) => (
                <div
                  key={header}
                  className={`table-cell ${width} p-4 text-xs font-semibold text-gray-500 uppercase bg-gray-50`}
                >
                  {header === "Status" ? (
                    <>
                      <span>Status</span>
                      <MultiDropdown
                        options={STATUS_OPTIONS}
                        selectedOptions={selectedStatuses}
                        onChange={handleStatusChange}
                      />
                    </>
                  ) : (
                    header
                  )}
                </div>
              ))}
            </div>
            {filteredIssues.map((issue, index) => (
              <div
                className={`table-row ${
                  index % 2 === 0 ? "bg-stone-50" : "bg-white"
                }`}
                key={issue.issueId}
              >
                <div className="table-cell font-semibold p-4 w-[20%]">
                  {truncateText(issue.subject, 28)}
                </div>
                <div className="table-cell p-4 whitespace-nowrap w-[20%]">
                  {issue.issueId}
                </div>
                <div className="table-cell p-4 whitespace-nowrap w-[10%]">
                  {formatDate(issue.createdAt)}
                </div>
                <div className="table-cell p-4 whitespace-nowrap w-[15%]">
                  {issue.assignedTo.department}
                </div>
                <div className="table-cell p-4 w-[12%]">{issue.status}</div>
                <div className="table-cell p-4 text-center w-[10%]">
                  <div
                    className={`justify-center px-2.5 py-0.5 rounded-md ${
                      PRIORITY_CLASSES[issue.priority]
                    }`}
                  >
                    {issue.priority}
                  </div>
                </div>
                <div className="table-cell p-4 whitespace-nowrap w-[15%]">
                  {issue.closedDate
                    ? formatDate(issue.closedDate)
                    : "----------"}
                </div>
                <div className="table-cell p-4 underline text-blue-700 w-[8%]">
                  <Link
                    to={ROUTES.VIEW_ISSUES.replace(":issueId", issue.issueId)}
                  >
                    View
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default EmployeeIssueList;
