import {
  API_FIELD_NAMES,
  FIELD_HEADING,
  FIELD_HEADING_DESCRIPTION,
  FIELD_LABELS,
} from "../../utils/constants/keywords";

export const viewPageInputs = {
  Profile: {
    id: 0,
    heading: {
      label: FIELD_HEADING.PROFILE,
      field: FIELD_HEADING_DESCRIPTION.PROFILE,
    },
    fields: [
      { label: FIELD_LABELS.EMPLOYEE_ID, name: API_FIELD_NAMES.EMPLOYEE_ID },
      {
        label: FIELD_LABELS.EMPLOYEE_NAME,
        name: API_FIELD_NAMES.EMPLOYEE_NAME,
      },
      { label: FIELD_LABELS.DEPARTMENT, name: API_FIELD_NAMES.DEPARTMENT },
      {
        label: FIELD_LABELS.CONTACT_NUMBER,
        name: API_FIELD_NAMES.CONTACT_NUMBER,
      },
      {
        label: FIELD_LABELS.EMAIL_ADDRESS,
        name: API_FIELD_NAMES.EMAIL_ADDRESS,
      },
      {
        label: FIELD_LABELS.DATE_OF_BIRTH,
        name: API_FIELD_NAMES.DATE_OF_BIRTH,
      },
    ],
  },
  Personal: {
    id: 1,
    heading: {
      label: FIELD_HEADING.PERSONAL_DETAILS,
      field: FIELD_HEADING_DESCRIPTION.PERSONAL_DETAILS,
    },
    fields: [
      { label: FIELD_LABELS.FATHERS_NAME, name: API_FIELD_NAMES.FATHERS_NAME },
      { label: FIELD_LABELS.MOTHERS_NAME, name: API_FIELD_NAMES.MOTHERS_NAME },
      {
        label: FIELD_LABELS.MARITAL_STATUS,
        name: API_FIELD_NAMES.MARITAL_STATUS,
      },
      { label: FIELD_LABELS.SPOUSE, name: API_FIELD_NAMES.SPOUSE },
      {
        label: FIELD_LABELS.NO_OF_CHILDREN,
        name: API_FIELD_NAMES.NO_OF_CHILDREN,
      },
      { label: FIELD_LABELS.BLOOD_GROUP, name: API_FIELD_NAMES.BLOOD_GROUP },
      {
        label: FIELD_LABELS.EMERGENCY_CONTACT_NAME,
        name: API_FIELD_NAMES.EMERGENCY_CONTACT_NAME,
      },
      {
        label: FIELD_LABELS.EMERGENCY_CONTACT_NUMBER,
        name: API_FIELD_NAMES.EMERGENCY_CONTACT_NUMBER,
      },
      { label: FIELD_LABELS.NATIONALITY, name: API_FIELD_NAMES.NATIONALITY },
      { label: FIELD_LABELS.AADHAR_NO, name: API_FIELD_NAMES.AADHAR_NO },
      { label: FIELD_LABELS.PAN_CARD_NO, name: API_FIELD_NAMES.PAN_CARD_NO },
      { label: FIELD_LABELS.PASSPORT_NO, name: API_FIELD_NAMES.PASSPORT_NO },
      { label: FIELD_LABELS.ADDRESS, name: API_FIELD_NAMES.ADDRESS },
      { label: FIELD_LABELS.ZIP_CODE, name: API_FIELD_NAMES.ZIP_CODE },
    ],
  },
  education: {
    id: 2,
    heading: {
      label: FIELD_HEADING.EDUCATION_DETAILS,
      field: FIELD_HEADING_DESCRIPTION.EDUCATION_DETAILS,
    },
    fields: [
      { label: FIELD_LABELS.EDUCATION, name: API_FIELD_NAMES.EDUCATION },
      {
        label: FIELD_LABELS.UNIVERSITY_BOARD,
        name: API_FIELD_NAMES.UNIVERSITY_BOARD,
      },
      {
        label: FIELD_LABELS.FIELD_OF_STUDY,
        name: API_FIELD_NAMES.FIELD_OF_STUDY,
      },
      { label: FIELD_LABELS.START_DATE, name: API_FIELD_NAMES.START_DATE },
      { label: FIELD_LABELS.END_DATE, name: API_FIELD_NAMES.END_DATE },
      {
        label: FIELD_LABELS.GRADES_PERCENTAGE,
        name: API_FIELD_NAMES.GRADES_PERCENTAGE,
      },
    ],
  },
  certification: {
    id: 3,
    heading: {
      label: FIELD_HEADING.CERTIFICATION_DETAILS,
      field: FIELD_HEADING_DESCRIPTION.CERTIFICATION_DETAILS,
    },
    fields: [
      {
        label: FIELD_LABELS.CERTIFICATION_NAME,
        name: API_FIELD_NAMES.CERTIFICATION_NAME,
      },
      { label: FIELD_LABELS.INSTITUTE, name: API_FIELD_NAMES.INSTITUTE },
    ],
  },
  [API_FIELD_NAMES.SKILLSET_OBJ]: {
    id: 4,
    heading: {
      label: FIELD_HEADING.SKILLSET,
      field: FIELD_HEADING_DESCRIPTION.SKILLSET,
    },
    fields: [{ label: FIELD_LABELS.SKILLSET, name: API_FIELD_NAMES.SKILL_SET }],
  },
  currentEmployment: {
    id: 5,
    heading: {
      label: FIELD_HEADING.EMPLOYMENT_DETAILS,
      field: FIELD_HEADING_DESCRIPTION.EMPLOYMENT_DETAILS,
    },
    fields: [
      { label: FIELD_LABELS.DESIGNATION, name: API_FIELD_NAMES.DESIGNATION },
      {
        label: FIELD_LABELS.EMPLOYMENT_TYPE,
        name: API_FIELD_NAMES.EMPLOYMENT_TYPE,
      },
      { label: FIELD_LABELS.CLIENT, name: API_FIELD_NAMES.CLIENT },
      { label: FIELD_LABELS.TAGGING, name: API_FIELD_NAMES.TAGGING },
      { label: FIELD_LABELS.STATUS, name: API_FIELD_NAMES.STATUS },
      { label: FIELD_LABELS.SUPERVISOR, name: API_FIELD_NAMES.SUPERVISOR },
      { label: FIELD_LABELS.MY_PROJECT, name: API_FIELD_NAMES.MY_PROJECT },
      { label: FIELD_LABELS.START_DATE, name: API_FIELD_NAMES.START_DATE },
      { label: FIELD_LABELS.END_DATE, name: API_FIELD_NAMES.END_DATE },
    ],
  },
  previousEmployments: {
    id: 6,
    heading: {
      label: FIELD_HEADING.PREVIOUS_EMPLOYMENT_DETAILS,
      field: "",
    },
    fields: [
      { label: FIELD_LABELS.DESIGNATION, name: API_FIELD_NAMES.DESIGNATION },
      {
        label: FIELD_LABELS.EMPLOYMENT_TYPE,
        name: API_FIELD_NAMES.EMPLOYMENT_TYPE,
      },
      {
        label: FIELD_LABELS.TOTAL_RELEVANT_EXPERIENCE,
        name: API_FIELD_NAMES.TOTAL_RELEVANT_EXPERIENCE,
      },
      { label: FIELD_LABELS.COMPANY_NAME, name: API_FIELD_NAMES.COMPANY_NAME },
      {
        label: FIELD_LABELS.COMPANY_LOCATION,
        name: API_FIELD_NAMES.COMPANY_LOCATION,
      },
      { label: FIELD_LABELS.WORK_MODE, name: API_FIELD_NAMES.WORK_MODE },
      { label: FIELD_LABELS.BAND_LEVEL, name: API_FIELD_NAMES.BAND_LEVEL },
      { label: FIELD_LABELS.CTC_IN_LPA, name: API_FIELD_NAMES.CTC_IN_LPA },
    ],
  },
  [API_FIELD_NAMES.MY_TRAINING]: {
    id: 7,
    heading: {
      label: FIELD_HEADING.MY_TRAINING,
      field: FIELD_HEADING_DESCRIPTION.MY_TRAINING,
    },
    fields: [
      {
        label: FIELD_LABELS.IT_SETUP_TRAINING,
        name: API_FIELD_NAMES.IT_SETUP_TRAINING,
      },
      {
        label: FIELD_LABELS.SECURITY_TRAINING,
        name: API_FIELD_NAMES.SECURITY_TRAINING,
      },
      { label: FIELD_LABELS.FIRE_DRILL, name: API_FIELD_NAMES.FIRE_DRILL },
      {
        label: FIELD_LABELS.HEALTH_AND_SAFETY,
        name: API_FIELD_NAMES.HEALTH_AND_SAFETY,
      },
      { label: FIELD_LABELS.POSH, name: API_FIELD_NAMES.POSH },
      { label: FIELD_LABELS.OTHERS, name: API_FIELD_NAMES.OTHERS },
    ],
  },
  [API_FIELD_NAMES.BANK_DETAILS]: {
    id: 8,
    heading: {
      label: FIELD_HEADING.BANK_DETAILS,
      field: FIELD_HEADING_DESCRIPTION.BANK_DETAILS,
    },
    fields: [
      { label: FIELD_LABELS.BANK_NAME, name: API_FIELD_NAMES.BANK_NAME },
      {
        label: FIELD_LABELS.BANK_ACCOUNT_NO,
        name: API_FIELD_NAMES.BANK_ACCOUNT_NO,
      },
      { label: FIELD_LABELS.BRANCH, name: API_FIELD_NAMES.BRANCH },
      { label: FIELD_LABELS.IFSC, name: API_FIELD_NAMES.IFSC },
    ],
  },
  [API_FIELD_NAMES.PF_ACCOUNT_DETIALS]: {
    id: 9,
    heading: {
      label: FIELD_HEADING.PF_ACCOUNT_DETAILS,
      field: FIELD_HEADING_DESCRIPTION.PF_ACCOUNT_DETAILS,
    },
    fields: [
      { label: FIELD_LABELS.PF_NUMBER, name: API_FIELD_NAMES.PF_NUMBER },
      { label: FIELD_LABELS.UAN, name: API_FIELD_NAMES.UAN },
      { label: FIELD_LABELS.PF_DOJ, name: API_FIELD_NAMES.PF_DOJ },
    ],
  },
};
