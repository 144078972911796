export const roleTypes = [
  { id: 1, name: "Select" },
  { id: 2, name: "HR" },
  { id: 3, name: "Finance" },
  { id: 4, name: "ITSupport" },
  { id: 5, name: "Employee" },
  { id: 6, name: "Admin" },
  { id: 7, name: "DeliveryExcellence" },
  { id: 8, name: "TAG" },
  { id: 9, name: "Sales" },
  { id: 10, name: "Marketing" },
  { id: 11, name: "SeniorManagement" },
];

export const maritalStatusType = [
  { id: 1, name: "Select" },
  { id: 2, name: "Single" },
  { id: 3, name: "Married" },
  { id: 4, name: "Divorced" },
  { id: 5, name: "Widowed" },
];

export const bloodGroups = [
  { id: 1, name: "Select" },
  { id: 2, name: "O+" },
  { id: 3, name: "O-" },
  { id: 4, name: "A+" },
  { id: 5, name: "A-" },
  { id: 6, name: "B+" },
  { id: 7, name: "B-" },
  { id: 8, name: "AB+" },
  { id: 9, name: "AB-" },
];

export const educationLevel = [
  { id: 1, name: "Select" },
  { id: 2, name: "Secondary" },
  { id: 3, name: "Diploma" },
  { id: 4, name: "Higher Secondary" },
  { id: 5, name: "Bachelors" },
  { id: 6, name: "Masters" },
  { id: 7, name: "PhD" },
];

export const employmentType = [
  { id: 1, name: "Select" },
  { id: 2, name: "FullTime" },
  { id: 3, name: "PartTime" },
  { id: 4, name: "Temporary" },
  { id: 5, name: "Contract" },
  { id: 6, name: "Consultant" },
  { id: 7, name: "Internship" },
];

export const taggingType = [
  { id: 1, name: "Select" },
  { id: 2, name: "Billable" },
  { id: 3, name: "NonBillable" },
  { id: 4, name: "Bench" },
  { id: 5, name: "Investment" },
  { id: 6, name: "DNB" },
];

export const statusType = [
  { id: 1, name: "Select" },
  { id: 2, name: "Active" },
  { id: 3, name: "Resigned" },
  { id: 4, name: "Buffer" },
  { id: 5, name: "InActive" },
  { id: 6, name: "Absconded" },
  { id: 7, name: "NewJoinee" },
  { id: 8, name: "Terminated" },
];

export const myProjectType = [
  { id: 1, name: "Select" },
  { id: 2, name: "Wellzy" },
  { id: 3, name: "Test Serv" },
  { id: 4, name: "Wiztap" },
  { id: 5, name: "Inernal App's" },
  { id: 6, name: "Client project" },
];

export const clientType = [
  { id: 1, name: "Select" },
  { id: 2, name: "Sony" },
  { id: 3, name: "Philips" },
  { id: 4, name: "Fossil" },
  { id: 5, name: "Amazon" },
  { id: 6, name: "MediaCorp" },
  { id: 7, name: "ANZ" },
  { id: 8, name: "3SC" },
  { id: 9, name: "Internal" },
];

export const workModeType = [
  { id: 1, name: "Select" },
  { id: 2, name: "Work From Home" },
  { id: 3, name: "Work From Office" },
  { id: 4, name: "Hybrid" },
  { id: 5, name: "Remote" },
];

export const bandLevelType = [
  { id: 1, name: "Select" },
  { id: 2, name: "A1" },
  { id: 3, name: "A2" },
  { id: 4, name: "A3" },
  { id: 5, name: "A4" },
  { id: 6, name: "A5" },
  { id: 7, name: "A6" },
  { id: 8, name: "B1" },
  { id: 9, name: "B2" },
  { id: 10, name: "B3" },
];

export const completionStatusType = [
  { id: 1, name: "Select" },
  { id: 2, name: "InComplete" },
  { id: 3, name: "InProgress" },
  { id: 4, name: "Done" },
];

export const breadCrumbSteps = [
  { id: "01", name: "Profile", status: "current" },
  { id: "02", name: "Personal", status: "upcoming" },
  { id: "03", name: "Education", status: "upcoming" },
  { id: "04", name: "Employment", status: "upcoming" },
  { id: "05", name: "Training", status: "upcoming" },
  { id: "06", name: "Bank Details", status: "upcoming" },
];
