import React from "react";
import { Link } from "react-router-dom";

const LandingCard = ({ project }) => {
  return (
    <Link to={project.href}>
      <div key={project.name} className="bg-white rounded-2xl">
        <div className="flex items-center justify-center bg-gradient-to-b from-blue-500 to-white rounded-t-2xl">
          <img
            className="size-36  object-cover"
            src={project.imageUrl}
            alt=""
          />
        </div>

        <div className="pl-6 space-y-2 pb-8">
          <h3 className="mt-6 text-xl font-bold  tracking-tight ">
            {project.name}
          </h3>
          <p className="text-sm text-[#6B7280] font-medium  ">
            {project.description}
          </p>
        </div>
      </div>
    </Link>
  );
};

export default React.memo(LandingCard);
