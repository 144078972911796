import * as React from "react";
import PropTypes from "prop-types";

function StatCard({ icon, count, label }) {
  return (
    <section className="flex mx-5 my-5 gap-4 py-5 pr-36 pl-5 bg-white rounded max-md:pr-5">
      <div className="flex justify-center items-center self-start px-2 w-10 h-10 bg-blue-700 rounded">
        <img loading="lazy" src={icon} alt="" className="w-6 aspect-square" />
      </div>
      <div className="flex flex-col">
        <h2 className="justify-center text-xl font-semibold text-black whitespace-nowrap">
          {count}
        </h2>
        <p className="text-xs font-medium text-gray-500">{label}</p>
      </div>
    </section>
  );
}

function IssueCards({
  totalIssues,
  highPriorityIssues,
  inProgressIssues,
  resolvedIssues,
}) {
  const cardData = [
    {
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/1747200ac8624f19569a1450b8166d78eedea19aca7ce712c960dd2527d06b65?apiKey=d8e91bdc04594ed59f135bc4e59e9ebd&",
      count: totalIssues,
      label: "Total Issues",
    },
    {
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/1747200ac8624f19569a1450b8166d78eedea19aca7ce712c960dd2527d06b65?apiKey=d8e91bdc04594ed59f135bc4e59e9ebd&",
      count: highPriorityIssues,
      label: "High Priority",
    },
    {
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/1747200ac8624f19569a1450b8166d78eedea19aca7ce712c960dd2527d06b65?apiKey=d8e91bdc04594ed59f135bc4e59e9ebd&",
      count: inProgressIssues,
      label: "In Progress",
    },
    {
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/1747200ac8624f19569a1450b8166d78eedea19aca7ce712c960dd2527d06b65?apiKey=d8e91bdc04594ed59f135bc4e59e9ebd&",
      count: resolvedIssues,
      label: "Resolved",
    },
  ];

  return (
    <main className="flex gap-5 justify-between rounded-lg max-md:flex-wrap">
      {cardData.map((card, index) => (
        <StatCard key={index} {...card} />
      ))}
    </main>
  );
}

IssueCards.propTypes = {
  totalIssues: PropTypes.number.isRequired,
  highPriorityIssues: PropTypes.number.isRequired,
  inProgressIssues: PropTypes.number.isRequired,
  resolvedIssues: PropTypes.number.isRequired,
};

export default IssueCards;
