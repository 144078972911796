import React, { useState, useEffect } from "react";
import { employeeIssuesTitle as empissues } from "../../lib/label";
import Container from "../ReusableComponents/Container";
import IssueCards from "../HrIssues/IssueCards";
import { getHelper } from "../../utils/axios/apiServices";
import IssuesTable from "./IssuesTable";
import { ISSUES } from "../../lib/api";

const HrIssues = () => {
  const [issueData, setIssueData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchIssues = async () => {
      try {
        const response = await getHelper(
          ISSUES.GET_HR_ISSUES,
          (data) => {
            // Process data
            const formattedData = data.map((issue) => ({
              id: issue.issueId,
              employeeName: issue.name,
              employeeId: issue.employeeID,
              client: issue.client,
              raisedDate: new Date(issue.createdAt).toLocaleDateString("en-GB"),
              priority: issue.priority,
              status: issue.status,
              closedDate:
                issue.status === "Closed"
                  ? new Date(issue.closedDate).toLocaleDateString("en-GB")
                  : "---",
            }));

            setIssueData(formattedData);
          },
          null,
          "Issues fetched successfully",
          "Failed to fetch issues"
        );
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchIssues();
  }, []);

  // Filter handlers
  const [clientFilter, setClientFilter] = useState([]);
  const [priorityFilter, setPriorityFilter] = useState([]);
  const [statusFilter, setStatusFilter] = useState([]);

  const handleClientFilterChange = (option) => {
    setClientFilter((prevFilter) =>
      prevFilter.includes(option)
        ? prevFilter.filter((item) => item !== option)
        : [...prevFilter, option]
    );
  };

  const handlePriorityFilterChange = (option) => {
    if (priorityFilter.includes(option)) {
      setPriorityFilter(priorityFilter.filter((item) => item !== option));
    } else {
      setPriorityFilter([...priorityFilter, option]);
    }
  };

  const handleStatusFilterChange = (option) => {
    if (statusFilter.includes(option)) {
      setStatusFilter(statusFilter.filter((item) => item !== option));
    } else {
      setStatusFilter([...statusFilter, option]);
    }
  };

  const totalIssues = issueData.length;
  const highPriorityIssues = issueData.filter(
    (issue) => issue.priority === "High"
  ).length;
  const inProgressIssues = issueData.filter(
    (issue) => issue.status === "In Progress"
  ).length;
  const resolvedIssues = issueData.filter(
    (issue) => issue.status === "Closed"
  ).length;

  return (
    <div className="relative flex flex-col items-center pb-14 bg-gray-100">
      <Container>
        <IssueCards
          totalIssues={totalIssues}
          highPriorityIssues={highPriorityIssues}
          inProgressIssues={inProgressIssues}
          resolvedIssues={resolvedIssues}
        />

        <div className="mt-5 mx-5 w-full flex justify-start">
          <div className="flex flex-col">
            <span className="text-xl font-semibold">
              {empissues.empIssuesTitle}
            </span>
            <span className="text-slate-500">
              {empissues.empIssuesSubTitle}
            </span>
          </div>
        </div>

        <IssuesTable
          data={issueData}
          clientFilter={clientFilter}
          priorityFilter={priorityFilter}
          statusFilter={statusFilter}
          handleClientFilterChange={handleClientFilterChange}
          handlePriorityFilterChange={handlePriorityFilterChange}
          handleStatusFilterChange={handleStatusFilterChange}
          loading={loading}
          error={error}
        />
      </Container>
    </div>
  );
};

export default HrIssues;
