import { FunnelIcon } from "@heroicons/react/24/outline";
import React from "react";
import { EMPLOYEE_STATUS, SENIOR_MANAGEMENT_PAGE_LABELS } from "../../../utils/constants/keywords";

const EmployeeDetailsTable = ({ currentPageEmployeeList, navigateViewPage }) => (
    <section className=" mt-4 w-full max-md:max-w-full">
      <div className="overflow-x-auto bg-white rounded-lg shadow-sm">
        <table className="min-w-full bg-white">
          <thead className="bg-gray-50 text-gray-500 uppercase text-xs font-semibold">
            <tr>
              <th className="p-4 text-left">
                {SENIOR_MANAGEMENT_PAGE_LABELS.EMPLOYEE_NAME}
              </th>
              <th className="p-4 text-left">
                {SENIOR_MANAGEMENT_PAGE_LABELS.SUPERVISOR}
              </th>
              <th className="p-4 text-left cursor-pointer relative ">
                {SENIOR_MANAGEMENT_PAGE_LABELS.DEPARTMENT}
                <FunnelIcon className="w-3.5 inline-flex ml-1 align-bottom" />
              </th>
              <th className="p-4 text-left cursor-pointer relative">
                {SENIOR_MANAGEMENT_PAGE_LABELS.COST_CENTER}
                <FunnelIcon className="w-3.5 inline-flex ml-1 align-bottom" />
              </th>
              <th className="p-4 text-left cursor-pointer relative">
                {SENIOR_MANAGEMENT_PAGE_LABELS.TAGGING}
                <FunnelIcon className="w-3.5 inline-flex ml-1 align-bottom" />
              </th>
              <th className="p-4 text-left cursor-pointer relative">
                {SENIOR_MANAGEMENT_PAGE_LABELS.STATUS}
                <FunnelIcon className="w-3.5 inline-flex ml-1 align-bottom" />
              </th>
  
              <th className="p-4 text-left">
                {SENIOR_MANAGEMENT_PAGE_LABELS.ACTIONS}
              </th>
            </tr>
          </thead>
          <tbody className="text-gray-500">
            {currentPageEmployeeList?.map((employee, index) => (
              <tr
                key={employee?._id}
                className={index % 2 === 0 ? "bg-white" : "bg-stone-50"}
              >
                <td className="p-4 flex gap-4 items-center">
                  <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/7d7ccfdc832c967081f4888640171d79a793d82f50819c8d55b9dd827799a8d0?apiKey=d8e91bdc04594ed59f135bc4e59e9ebd"
                    className="shrink-0 w-10 aspect-square"
                  />
                  <div>
                    <div className="font-medium text-gray-900">
                      {employee.name}
                    </div>
                    <div className="text-gray-500">ID: {employee.employeeId}</div>
                  </div>
                </td>
                <td className="p-4">
                  {employee?.currentEmployment?.[0]?.supervisor}
                </td>
                <td className="p-4">{employee?.role}</td>
                <td className="p-4">
                  {employee?.currentEmployment?.[0]?.client}
                </td>
                <td className="p-4">
                  {employee?.currentEmployment?.[0]?.tagging}
                </td>
                <td className="p-4">
                  {(employee?.isActive && EMPLOYEE_STATUS.ACTIVE) ||
                    EMPLOYEE_STATUS.INACTIVE}
                </td>
                <td
                  className="p-4 text-blue-700 underline cursor-pointer"
                  onClick={() => navigateViewPage(employee)}
                >
                  View
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </section>
  );

  export default React.memo(EmployeeDetailsTable)