import React, { useEffect, useState } from "react";
import { getHelper } from "../utils/axios/apiServices";
import { USER } from "../lib/api";
import Container from "../Components/ReusableComponents/Container";
import EmployeeTableListing from "../Components/SeniorManagement/Dashboard/EmployeeTableListing";
import Stats from "../Components/SeniorManagement/Dashboard/Stats";

const SeniorManagement = () => {
  const [employees, setEmployees] = useState([]);
  const [taggingCounts, setTaggingCounts] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      const resp = await getHelper(USER.GET_ALL_USERS, setEmployees);
      setTaggingCounts(resp?.taggingCounts);
    };
    fetchData();
  }, []);

  return (
    <>
      <Container>
        <Stats
          taggingCounts={taggingCounts}
          employeesCount={employees.length}
        />
        <EmployeeTableListing employees={employees} />
      </Container>
    </>
  );
};

export default SeniorManagement;
