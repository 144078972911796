import React from "react";

const HeaderContent = ({emplist}) => (
    <div className="flex flex-col gap-y-2">
      <span className="text-2xl font-semibold">{emplist.empList}</span>
      <span className="text-slate-700 font-normal text-4">
        {emplist.empListDesc}
      </span>
    </div>
  );
  export default React.memo(HeaderContent)