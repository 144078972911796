// src/components/HrIssues/IssuesTable.js

import React from "react";
import { Link } from "react-router-dom";
import { ROUTES } from "../../utils/constants/routes";
import MultiDropdown from "../ReusableComponents/MultiselectDropdown";
import {
  PRIORITY_CLASSES,
  COSTCENTER_OPTIONS,
  STATUS_OPTIONS,
  PRIORITY_OPTIONS,
} from "../../utils/constants/dropdown";

const IssuesTable = ({
  data,
  clientFilter,
  priorityFilter,
  statusFilter,
  handleClientFilterChange,
  handlePriorityFilterChange,
  handleStatusFilterChange,
  loading,
  error,
}) => {
  const applyFilters = () => {
    return data.filter((item) => {
      return (
        (clientFilter.length === 0 || clientFilter.includes(item?.client)) &&
        (priorityFilter.length === 0 ||
          priorityFilter.includes(item?.priority)) &&
        (statusFilter.length === 0 || statusFilter.includes(item?.status))
      );
    });
  };

  const filteredData = applyFilters();

  return (
    <section className="px-5 mt-4 w-full max-md:max-w-full">
      <div className="overflow-x-auto bg-white rounded-lg shadow-sm">
        {loading ? (
          <p>Loading...</p>
        ) : error ? (
          <p>Error loading data: {error.message}</p>
        ) : (
          <table className="min-w-full bg-white">
            <thead className="bg-gray-50 text-gray-500 uppercase text-xs font-semibold">
              <tr>
                <th className="p-4 text-left">Employee Name & ID</th>
                <th className="p-4 text-left">Issue ID</th>
                <th className="p-4 text-left flex">
                  Cost Center
                  <MultiDropdown
                    options={COSTCENTER_OPTIONS}
                    selectedOptions={clientFilter}
                    onChange={handleClientFilterChange}
                  />
                </th>
                <th className="p-4 text-left">Raised Date</th>
                <th className="p-4 text-left flex">
                  Priority
                  <MultiDropdown
                    options={PRIORITY_OPTIONS}
                    selectedOptions={priorityFilter}
                    onChange={handlePriorityFilterChange}
                  />
                </th>
                <th className="p-4 text-left">
                  Status
                  <MultiDropdown
                    options={STATUS_OPTIONS}
                    selectedOptions={statusFilter}
                    onChange={handleStatusFilterChange}
                  />
                </th>
                <th className="p-4 text-left">Closed Date</th>
                <th className="p-4 text-left">Action</th>
              </tr>
            </thead>
            <tbody className="text-gray-500">
              {filteredData.map((item, index) => (
                <tr
                  key={index}
                  className={index % 2 === 0 ? "bg-white" : "bg-stone-50"}
                >
                  <td className="p-4 flex gap-4 items-center">
                    <div>
                      <div className="font-medium text-gray-900">
                        {item.employeeName}
                      </div>
                      <div className="text-gray-500">ID: {item.employeeId}</div>
                    </div>
                  </td>
                  <td className="p-4">{item.id}</td>
                  <td className="p-4">{item.client}</td>
                  <td className="p-4">{item.raisedDate}</td>
                  <td className="p-4 text-red-800 text-center">
                    <div
                      className={`justify-center px-2.5 py-0.5 rounded-md ${
                        PRIORITY_CLASSES[item.priority]
                      }`}
                    >
                      {item.priority}
                    </div>
                  </td>
                  <td className="p-4">{item.status}</td>
                  <td className="p-4">
                    {item.status === "Closed" ? item.closedDate : "---"}
                  </td>
                  <td className="p-4 text-blue-700 underline">
                    <Link to={`${ROUTES.VIEW_EMPLOYEEISSUES}/${item.id}`}>
                      View
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </section>
  );
};

export default IssuesTable;
