import React, { useState } from "react";
import { useParams } from "react-router-dom";
import Header from "../Header";
import Container from "../ReusableComponents/Container";
import { employeeDetailIssues as empissues } from "../../lib/label";
import ReassignModal from "./ReassignModal";
import useEmployeeIssueData from "../../hooks/useEmployeeIssueData";

const EmployeeIssueDetails = () => {
  const { issueId } = useParams();
  const { employeeData, loading, error } = useEmployeeIssueData(issueId);
  const [showModal, setShowModal] = useState(false);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;
  if (!employeeData) return <div>No data found</div>;

  const {
    name,
    employeeID,
    subject,
    issueDescription,
    documentUrl,
    userImage,
    _id,
  } = employeeData;

  return (
    <div className="relative flex flex-col items-center pb-14 bg-gray-100">
      <Container>
        <div className="mt-5 mx-5 w-full flex justify-start">
          <div className="flex flex-col">
            <span className="text-xl font-semibold">
              {empissues.empIssuesDetail}
            </span>
            <span className="text-slate-500">
              {empissues.myIssuesSubTitleDetail}
            </span>
          </div>
        </div>

        <div className="flex flex-col justify-center p-6 bg-white rounded-lg shadow-sm max-md:px-5">
          <div className="flex flex-col items-start pb-3 max-md:max-w-full">
            <div className="flex gap-5 justify-between self-stretch w-full text-sm max-md:flex-wrap max-md:max-w-full">
              <div className="flex gap-4 my-auto leading-[143%]">
                <img
                  loading="lazy"
                  src={userImage}
                  alt={`${name}'s profile`}
                  className="shrink-0 w-10 aspect-square"
                />
                <div className="flex flex-col">
                  <div className="font-medium text-gray-900">{name}</div>
                  <div className="text-gray-500">ID: {employeeID}</div>
                </div>
              </div>
              <div className="flex gap-5 justify-between font-medium whitespace-nowrap max-md:flex-wrap">
                <button
                  className="flex items-center justify-center px-5 py-2.5 rounded-md border border-gray-300 cursor-pointer text-gray-700"
                  onClick={() => setShowModal(true)}
                >
                  Reassign
                </button>
                <div className="flex gap-3">
                  <button className="px-5 py-2.5 text-white bg-blue-700 rounded-md">
                    Accept
                  </button>
                  <button className="px-5 py-2.5 text-gray-500 border border-gray-300 rounded-md">
                    Decline
                  </button>
                </div>
              </div>
            </div>
            <div className="flex flex-col mt-10 text-sm font-medium leading-6">
              <div className="text-gray-900">Subject</div>
              <div className="mt-2 text-gray-400">{subject}</div>
            </div>
            <div className="flex flex-col self-stretch mt-10 text-sm font-medium leading-6 max-md:max-w-full">
              <div className="text-gray-900">Description</div>
              <div className="mt-2 text-gray-400">{issueDescription}</div>
            </div>
            <div className="flex flex-col mt-10 max-w-full text-sm font-medium">
              <div className="leading-6 text-gray-900">Attachments</div>
              <div className="flex gap-2 mt-2 text-gray-400">
                {documentUrl ? (
                  <a
                    href={documentUrl}
                    className="text-blue-700"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Document Link
                  </a>
                ) : (
                  <div>No attachments</div>
                )}
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/4c92b2ef6d4bb6ab9e44734db06cfd948838761b438997a9f921b7b5b0d55bae?apiKey=d8e91bdc04594ed59f135bc4e59e9ebd&"
                  className="shrink-0 w-5 aspect-square"
                  alt="Attachment icon"
                />
              </div>
            </div>
            <div className="flex flex-col self-stretch mt-10 text-sm font-medium leading-6">
              <div className="leading-6 text-gray-900">Comments</div>
              <div className="flex gap-3 mt-5 max-md:flex-wrap max-md:max-w-full">
                <input
                  className="flex-1 p-2.5 text-sm font-normal text-gray-700 bg-gray-100 border border-gray-300 rounded-lg outline-none"
                  placeholder="Add Comment"
                />
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/31b4820707f31d531e79aba17b893b2732cc32d965e5beaf3f5de423896e2f19?apiKey=d8e91bdc04594ed59f135bc4e59e9ebd&"
                  className="shrink-0 my-auto w-6 aspect-square"
                  alt="Comment icon"
                />
              </div>
            </div>
          </div>
        </div>
      </Container>

      <ReassignModal
        show={showModal}
        onClose={() => setShowModal(false)}
        _id={_id}
      />
    </div>
  );
};

export default EmployeeIssueDetails;
