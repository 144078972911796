import { useState, useEffect } from "react";
import { getHelper } from "../utils/axios/apiServices";
import LocalStorage from "../utils/StorsgeUtil/LocalStorage";
import { ISSUES } from "../lib/api";

const useIssues = () => {
  const [issues, setIssues] = useState([]);
  const [filteredIssues, setFilteredIssues] = useState([]);

  useEffect(() => {
    // Fetch all issues
    getHelper(
      ISSUES.GET_ALL_MY_ISSUES,
      (data) => {
        setIssues(data);
      },
      null,
      "Failed to fetch issues"
    );
  }, []);

  useEffect(() => {
    // Filter issues based on logged-in user's employeeID
    const userData = JSON.parse(LocalStorage.getUserData());

    const loggedInUserEmployeeID = userData?.employeeId;

    if (loggedInUserEmployeeID) {
      const filteredIssues = issues.filter((issue) => {
        return issue.employeeID === loggedInUserEmployeeID;
      });
      setFilteredIssues(filteredIssues);
    }
  }, [issues]);

  return filteredIssues;
};

export default useIssues;
