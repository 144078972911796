const LocalStorage = {
  isLoggedIn: () => {
    const isLoggedIn = localStorage.getItem("isLoggedIn");
    return isLoggedIn === "true";
  },
  getUserData: () => {
    return localStorage.getItem("userData");
  },
  getToken: () => {
    return localStorage.getItem("token");
  },

  login: (responseData) => {
    localStorage.setItem("isLoggedIn", true);
    localStorage.setItem("userData", JSON.stringify(responseData.data.user));
    localStorage.setItem("token", responseData.token);
  },
  logout: () => {
    localStorage.removeItem("isLoggedIn");
    localStorage.removeItem("userData");
    localStorage.removeItem("token");
  },
};

export default LocalStorage;
