import React, { useState } from "react";
import Header from "../Header";
import { employeeIssues as empissues } from "../../lib/label.js";
import { ROUTES } from "../../utils/constants/routes";
import Container from "../ReusableComponents/Container.js";
import CreateIssueForm from "./CreateIssueForm";
import EmployeeIssueList from "./EmployeeIssueList";

import useUserRole from "../../hooks/useUserRole";
import useIssues from "../../hooks/useIssues";

const MyIssues = () => {
  const [isAddingIssue, setIsAddingIssue] = useState(false);
  const [newIssuesButtonActive, setNewIssuesButtonActive] = useState(true);
  const userRole = useUserRole();
  const filteredIssues = useIssues();

  const handleNewIssueClick = () => {
    setIsAddingIssue(true);
    setNewIssuesButtonActive(false);
  };

  const handleCancel = () => {
    setIsAddingIssue(false);
    setNewIssuesButtonActive(true);
  };

  return (
    <div className="relative flex flex-col items-center pb-14 bg-gray-100">
      <Container>
        <div className="m-2 px-5 w-full flex justify-between items-center">
          <div className="flex flex-col">
            <span className="text-xl font-semibold">{empissues.empIssues}</span>
            <span className="text-slate-500">{empissues.myIssuesSubTitle}</span>
          </div>
          <div className="flex gap-4">
            {userRole === "HR" && (
              <a href={ROUTES.HR_ISSUES}>
                <button
                  type="button"
                  className="rounded-sm border border-blue-600 bg-white p-3 text-sm font-semibold text-blue-600 shadow-sm flex items-center"
                >
                  <div className="px-4">HR</div>
                  <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/80efa7175ba5cb97e482682ea3e465b0dd209817143ce5345ba087de1e6701c5?apiKey=d8e91bdc04594ed59f135bc4e59e9ebd&"
                    className="shrink-0 aspect-[1.06] w-[17px]"
                  />
                </button>
              </a>
            )}
            <button
              type="button"
              className={`rounded-sm p-3 text-sm font-semibold text-white shadow-sm ${
                newIssuesButtonActive
                  ? "bg-blue-700"
                  : "bg-blue-400 cursor-not-allowed"
              }`}
              onClick={handleNewIssueClick}
              disabled={!newIssuesButtonActive}
            >
              NEW ISSUES
            </button>
          </div>
        </div>
        {isAddingIssue && (
          <CreateIssueForm onCancel={handleCancel} onSubmit={handleCancel} />
        )}

        <EmployeeIssueList issues={filteredIssues} />
      </Container>
    </div>
  );
};

export default MyIssues;
