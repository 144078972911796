import React from "react";
import { ACTION_BUTTONS, BREADCRUMB_STATUS } from "../../utils/constants/keywords";

const FormActionButton = ({
    steps,
    handleBreadcrumbBack,
    handleBreadcrumbNext,
  }) => (
    <div className="absolute w-full left-0 bottom-7 px-5 lg:px-20   mt-6 flex items-center justify-end gap-x-5 lg:gap-x-20">
      <div
        className="text-sm mx-5 lg:mx-14 my-3 font-medium leading-6  text-[#94a8d1] cursor-pointer"
        onClick={handleBreadcrumbBack}
      >
        {ACTION_BUTTONS.BACK}
      </div>
      {(steps[steps.length-1].status !== BREADCRUMB_STATUS.CURRENT && (
        <div
          className="cursor-pointer rounded bg-[#6466F1] px-5 lg:px-14 py-3 text-sm font-medium text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          onClick={handleBreadcrumbNext}
        >
          {ACTION_BUTTONS.NEXT}
        </div>
      )) || (
        <button
          className="cursor-pointer rounded bg-[#6466F1] px-5 lg:px-14 py-3 text-sm font-medium text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          type="submit"
        >
          {ACTION_BUTTONS.SUBMIT}
        </button>
      )}
    </div>
  );
  export default React.memo(FormActionButton)