import React, { useState } from "react";

const Dropdown = ({ options, onSelect }) => {
  return (
    <div className="absolute top-12 mt-2 py-2 w-48 bg-white rounded-md shadow-xl z-20">
      {options.map((option, index) => (
        <div
          key={index}
          className="block border-b text-start px-4 py-2 text-gray-800 hover:bg-gray-200"
          onClick={() => onSelect(option)}
        >
          {option}
        </div>
      ))}
    </div>
  );
};

export default Dropdown;
