import React from "react";
import { editPageInputs } from "./editPageInputs";
import { ACTION_BUTTONS, FIELD_LABELS } from "../../utils/constants/keywords";
import { UserCircleIcon } from "@heroicons/react/24/solid";

const ProfilePhoto = ({ photoData, isProfileImgButtonClick, fileInputRef,handleChange }) => (
    <div className="mt-10 grid grid-cols-1 gap-x-20 gap-y-8 sm:grid-cols-6">
      <div className="col-span-full">
        <label
          htmlFor="photo"
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          {FIELD_LABELS.PHOTO}
        </label>
        <div className="mt-2 flex items-center gap-x-3">
          {(photoData.length > 0 && (
            <img
              src={photoData}
              className="h-12 w-12 rounded-full"
              alt=""
            />
          )) || (
            <UserCircleIcon
              className="h-12 w-12 text-gray-300"
              aria-hidden="true"
            />
          )}
          <button
            type="button"
            onClick={isProfileImgButtonClick}
            className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          >
            {ACTION_BUTTONS.CHANGE}
          </button>
          <input
            className="hidden"
            ref={fileInputRef}
            name={editPageInputs.profilePhoto}
            type="file"
            onChange={handleChange}
            accept="image/*"
          />
        </div>
      </div>
    </div>
  );

  export default React.memo(ProfilePhoto)