import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { ACTION_BUTTONS } from "../../utils/constants/keywords";
import { ROUTES } from "../../utils/constants/routes";
import { Link } from "react-router-dom";

const SearchCreateSection = ({ setSearch }) => (
    <div className="flex flex-row items-center  gap-x-5 mt-6">
      <div className="flex items-center flex-1  w-[468px]   ">
        <div className="w-full">
          <label htmlFor="search-jobs" className="sr-only">
            Search
          </label>
          <div className="relative">
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-4">
              <MagnifyingGlassIcon
                className="h-5 w-5 text-gray-500 font-medium"
                aria-hidden="true"
              />
            </div>
            <input
              id="search-jobs"
              className="block w-full text-base flex-1 font-medium placeholder:text-base rounded-md border-0 bg-white py-4 pl-10 pr-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-500 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
              placeholder="Search"
              type="search"
              onChange={(event) => setSearch(event.target.value)}
            />
          </div>
        </div>
      </div>
      <Link to={ROUTES.CREATE_EMPLOYEE}>
        <div
          type="button"
          className="rounded-md   px-8 py-4 place-self-center text-sm font-medium text-white shadow-sm bg-indigo-600 hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          {ACTION_BUTTONS.CREATE}
        </div>
      </Link>
    </div>
  );
  export default SearchCreateSection