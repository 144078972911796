import axios from "axios";
import LocalStorage from "../StorsgeUtil/LocalStorage";
import { toast } from "react-toastify";
import { AUTH } from "../../lib/api";

const customFetch = axios.create({
  baseURL: "https://drishtiapi.wiztap.in/api/v1",
});

customFetch.interceptors.request.use((config) => {
  const user = LocalStorage.isLoggedIn();
  if (user) {
    config.headers["Authorization"] = `Bearer ${LocalStorage.getToken()}`;
  }
  return config;
});

export const getHelper = async (
  url,
  setState,
  params,
  successMsg,
  errorMsg
) => {
  try {
    const response = await customFetch.get(url, { params });
    setState(response.data.data);

    return response.data;
  } catch (error) {
    console.log(error);
    toast.error(error?.data?.data?.message);
  }
};

export const postHelper = async (url, data, successMsg, errorMsg) => {
  try {
    const response = await customFetch.post(url, data);
    toast.success(successMsg);

    return response.data.data;
  } catch (error) {
    console.log(error);
    toast.error(errorMsg);
  }
};

export const editHelper = async (url, data, successMsg, errorMsg) => {
  try {
    const response = await customFetch.put(url, data);
    if (response.data.success) {
      toast.success(successMsg);
      return response.data;
    } else {
      throw new Error(response.data.message || errorMsg);
    }
  } catch (error) {
    toast.error(errorMsg);
    console.error("EditHelper error:", error);
    return { success: false, message: error.message || errorMsg };
  }
};
