import { useState, useEffect } from "react";
import { getHelper } from "../utils/axios/apiServices";

const useEmployeeIssueData = (issueId) => {
  const [employeeData, setEmployeeData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchEmployeeData = async () => {
      try {
        setLoading(true);
        await getHelper(
          `/issues/view/${issueId}`,
          setEmployeeData,
          {},
          "Data fetched successfully",
          "Failed to fetch data"
        );
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    if (issueId) {
      fetchEmployeeData();
    }
  }, [issueId]);

  return { employeeData, loading, error };
};

export default useEmployeeIssueData;
