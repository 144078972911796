import React, { useEffect, useState } from "react";
import { employeeIssuesTitle as empissues  } from "../../../utils/constants/keywords.js"; 
import Pagination from "./Pagination.js";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../utils/constants/routes.js";
import HeaderContent from "../../ReusableComponents/HeaderContent.js";
import EmployeeDetailsTable from "./EmployeeDetailsTable.js";

const EmployeeTableListing = ({ employees }) => {
  const [pageNo, setPageNo] = useState(1);
  const totalPages = Math.ceil(employees.length / 10);
  const [paginatedEmployeeList, setPaginatedEmployeeList] = useState();
  const currentPageEmployeeList = paginatedEmployeeList?.[pageNo - 1];
  useEffect(() => {
    let result = [];
    let start = 0;
    let end = 10;
    for (let index = 0; index < totalPages; index++) {
      result[index] = employees?.slice(start, end);
      start += 10;
      end += 10;
    }
    setPaginatedEmployeeList(result);
  }, [employees]);
  const handlePageChange = (newPage) => {
    setPageNo(newPage);
  };

  const navigate = useNavigate();
  const navigateViewPage = (item) => {
    navigate(ROUTES.SENIOR_MANAGEMENT_EMPLOYEE_VIEW, {
      state: { employee: item },
    });
  };

  return (
    <div className="pb-16">
      <div className="relative flex flex-col items-center pb-6 ">
        <div className="mt-5 mx-5 w-full flex justify-start">
          <HeaderContent emplist={empissues} />
        </div>
        <EmployeeDetailsTable
          currentPageEmployeeList={currentPageEmployeeList}
          navigateViewPage={navigateViewPage}
        />
      </div>
      <Pagination
        totalPages={totalPages}
        currentPage={pageNo}
        onPageChange={handlePageChange}
      />
    </div>
  );
};

export default React.memo(EmployeeTableListing);
