import React, { useEffect, useState } from "react";
import EmployeeCard from "../Components/EmployeeListing/EmployeeCard.js";
import { employeeList as emplist } from "../utils/constants/keywords.js";
import { USER } from "../lib/api.js";
import { getHelper } from "../utils/axios/apiServices.js";
import SearchCreateSection from "../Components/EmployeeListing/SearchCreateSection.js";
import HeaderContent from "../Components/ReusableComponents/HeaderContent.js";

const EmployeeCards = ({ employees, search }) => (
  <section className="px-5 md:px-20  w-full ">
    <div className=" grid grid-cols-1 gap-y-1 md:grid-cols-2 sm:gap-x-6 sm:gap-y-6 pb-8  lg:grid-cols-3 xl:grid-cols-4 lg:gap-x-8 lg:gap-y-8 ">
      {employees
        .filter((e) => e.name.toLowerCase().includes(search.toLowerCase()))
        .map((employee) => (
          <EmployeeCard key={employee.employeeId} employee={employee} />
        ))}
    </div>
  </section>
);

const MyComponent = () => {
  const [employees, setEmployees] = useState([]);
  const [search, setSearch] = useState("");

  useEffect(() => {
    getHelper(USER.GET_ALL_USERS, setEmployees);
  }, []);

  return (
    <div className="flex flex-col items-center pb-14 bg-[#E9E9E9] h-screen ">
      <div className="mt-6 md:my-6 w-full flex flex-col lg:flex-row justify-between px-5 md:px-20 ">
        <HeaderContent emplist={emplist} />
        <SearchCreateSection setSearch={setSearch} />
      </div>
      <EmployeeCards employees={employees} search={search} />
    </div>
  );
};

export default MyComponent;
