// constants
export const PRIORITY_OPTIONS = ["Low", "Medium", "High"];
export const DEPARTMENT_OPTIONS = [
  "UnAssigned",
  "Admin",
  "DEG",
  "Finance",
  "HR",
  "IT Support",
  "Marketing",
  "Sales",
  "TAG",
];

export const REASSIGN_OPTIONS = [
  "Admin",
  "DEG",
  "Finance",
  "HR",
  "IT Support",
  "Marketing",
  "Sales",
  "TAG",
];

export const COSTCENTER_OPTIONS = [
  "Sony",
  "Amazon",
  "WizTap",
  "Wellzy",
  "Testserv",
  "Client vibe",
  "Mahindra",
];

// constants for EmployeeIssuesList
export const STATUS_OPTIONS = [
  "Open",
  "Pending",
  "InProgress",
  "Declined",
  "Closed",
  "Completed",
  "Reopened",
];

export const PRIORITY_CLASSES = {
  High: "bg-rose-100 text-red-800",
  Medium: "bg-violet-100 text-violet-800",
  Low: "bg-green-100 text-green-800",
};
