import React from "react";
import TextInputField from "../CreateEmployee/TextInputField";
import FormActionButtons from "./FormActionButtons";

const EmployeeArrayInputFields = ({
    inputs,
    formik,
    handleNestedChange,
    handleFormCancel,
    sectionTitle,
  }) => (
    <>
      {formik.values?.[sectionTitle]?.map((section, arrayIndex) => (
        <div
          key={section._id}
          className={`py-10 ${
            inputs.length !== 1
              ? "grid grid-cols-1 gap-x-20 gap-y-8 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4"
              : ""
          }`}
        >
          {inputs.map((input) => (
            <TextInputField
              key={input.id}
              input={input}
              value={formik.values[input.array]?.[arrayIndex]?.[input.name]}
              handleChange={(e) =>
                handleNestedChange(
                  input.array,
                  arrayIndex,
                  input.name,
                  e.target.value
                )
              }
              touched={formik.touched[input.array]?.[arrayIndex]?.[input.name]}
              errors={formik.errors[input.array]?.[arrayIndex]?.[input.name]}
            />
          ))}
        </div>
      ))}
      <FormActionButtons handleFormCancel={handleFormCancel} />
    </>
  );

  export default React.memo(EmployeeArrayInputFields)