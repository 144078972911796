import React from "react";
import { dateFormatInput } from "../../utils/dateConverter";
import { INPUT_TYPES } from "../../utils/constants/keywords";
import Dropdown from "./Dropdown";

const TextInput = ({
  label,
  required,
  type,
  name,
  value,
  handleChange,
  maxLength,
}) => (
  <>
    <label
      htmlFor={name}
      className="block text-sm font-medium leading-6 text-gray-900"
    >
      {label}
      {required && <span className="text-red-500"> *</span>}
    </label>
    <div className="mt-2">
      <input
        type={type}
        required={required}
        name={name}
        id={name}
        autoComplete="address-level2"
        className="block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        value={(type === INPUT_TYPES.DATE && dateFormatInput(value)) || value}
        onChange={handleChange}
        maxLength={maxLength}
      />
    </div>
  </>
);

const TextInputField = ({
  input,
  value,
  handleChange,
  touched,
  errors,
}) => {
  const { name, label, type, required, maxLength, select, selectionItems } =
    input;
  return (
    <form>
      <div className="sm:col-span-2 sm:col-start-1">
        {(!select && (
          <TextInput
            label={label}
            required={required}
            type={type}
            name={name}
            value={value}
            handleChange={handleChange}
            maxLength={maxLength}
          />
        )) || (
          <Dropdown
            selectionItems={selectionItems}
            label={label}
            value={value}
            onChange={handleChange}
            name={name}
            required={required}
          />
        )}
        {touched && errors && (
          <div className="text-red-500 text-sm mt-2">{errors}</div>
        )}
      </div>
    </form>
  );
};

export default React.memo(TextInputField);
