import React, { useState } from "react";
import axios from "axios";
import baseUrl, { AUTH } from "../lib/api";
import { useLocation, useNavigate } from "react-router-dom";
import {
  EyeIcon,
  EyeSlashIcon,
  EnvelopeIcon,
} from "@heroicons/react/24/outline";
import { toast } from "react-toastify";
import ForgotPassword from "./ForgotPassword";
import ResetPassword from "./ResetPassword";
import { ROUTES } from "../utils/constants/routes";
import { ERROR_MESSAGES, SUCCESS_MESSAGES } from "../utils/constants/messages";
import images from "../assets/images";
import { LOGIN_PAGE_LABELS, PROJECT_TITLE } from "../utils/constants/keywords";
import LocalStorage from "../utils/StorsgeUtil/LocalStorage";
import { editHelper } from "../utils/axios/apiServices";

const LoginPage = ({
  handleToForgotPage,
  username,
  setUsername,
  emailErrorMsg,
  password,
  showPassword,
  handleLogin,
  handleChange,
  isValidEmail,
  setPassword,
  togglePasswordVisibility,
  passwordErrorMsg,
}) => (
  <div className="flex min-h-full flex-1 flex-col justify-center  py-12">
    <div className="sm:mx-auto sm:w-full sm:max-w-sm">
      <h2 className=" text-left text-2xl font-bold leading-9 tracking-tight text-gray-900">
        {LOGIN_PAGE_LABELS.SIGN_IN_TITLE}
      </h2>
    </div>

    <div className="mt-6 sm:mx-auto sm:w-full sm:max-w-sm">
      <form className="space-y-6" action="#" method="POST">
        <div>
          <label
            htmlFor="email"
            className="block text-base font-medium  leading-6 text-gray-900"
          >
            {LOGIN_PAGE_LABELS.MAIL}
          </label>
          <div className="mt-2">
            <div className="flex items-center relative">
              <input
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                required
                value={username}
                placeholder="Enter your e-mail address"
                onChange={(e) => handleChange(e, setUsername)}
                className="username block w-full rounded-md  !border-2 py-4 px-6 text-gray-900  placeholder:text-gray-400 focus:ring-2  sm:text-sm sm:leading-6"
              />
              <span className="absolute right-3">
                <EnvelopeIcon className=" h-6 w-6 text-gray-300" />
              </span>
            </div>
            {emailErrorMsg && !isValidEmail(username) && (
              <p className="text-red-500 text-sm mt-2">{emailErrorMsg}</p>
            )}
          </div>
        </div>

        <div>
          <div className="flex items-center justify-between">
            <label
              htmlFor="password"
              className="block text-base font-medium  leading-6 text-gray-900"
            >
              {LOGIN_PAGE_LABELS.PASSWORD}
            </label>
          </div>
          <div className="mt-2">
            <div className="flex items-center relative">
              <input
                id="password"
                name="password"
                type={(showPassword && "text") || "password"}
                autoComplete="current-password"
                placeholder="Enter your Password"
                required
                value={password}
                onChange={(e) => handleChange(e, setPassword)}
                className=" pass block w-full rounded-md  !border-2 py-4 px-6 text-gray-900  placeholder:text-gray-400 sm:text- sm:leading-6"
              />
              <span
                className="absolute right-3 cursor-pointer"
                onClick={togglePasswordVisibility}
              >
                {(showPassword && (
                  <EyeIcon className=" h-6 w-6 text-gray-300" />
                )) || <EyeSlashIcon className=" h-6 w-6 text-gray-300 " />}
              </span>
            </div>

            {passwordErrorMsg && !password && (
              <p className="text-red-500 text-sm mt-2">{passwordErrorMsg}</p>
            )}
          </div>
          <div>
            <div className="text-base font-normal leading-6 mt-2  text-right">
              <div
                onClick={handleToForgotPage}
                className="font-semibold text-[#1D4ED8] hover:text-indigo-500 cursor-pointer"
              >
                {LOGIN_PAGE_LABELS.FORGOT_PASSWORD}
              </div>
            </div>
          </div>
        </div>

        <div>
          <button
            type="submit"
            className="flex w-full justify-center rounded-lg bg-[#1D4ED8] px-3 py-3 text-base font-normal leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            onClick={handleLogin}
          >
            {LOGIN_PAGE_LABELS.SIGN_IN_BUTTON}
          </button>
        </div>
      </form>
    </div>
  </div>
);

const LoginForm = () => {
  const location = useLocation();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showForgetPasswordModal, setShowForgetPasswordModal] = useState(false);
  const [emailErrorMsg, setEmailErrorMsg] = useState("");
  const [passwordErrorMsg, setPasswordErrorMsg] = useState("");
  const [newPasswordErrorMsg, setNewPasswordErrorMsg] = useState("");
  const [confirmPasswordErrorMsg, setConfirmPasswordErrorMsg] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [resetPasswordModal, setResetPasswordModal] = useState(
    location.state?.resetPassword || false
  );
  const handleForgetPassword = async (e) => {
    e.preventDefault();
    if (!username) {
      setEmailErrorMsg(ERROR_MESSAGES.LOGIN_VALID_EMAIL);
    } else if (!isValidEmail(username)) {
      setEmailErrorMsg(ERROR_MESSAGES.LOGIN_VALID_EMAIL);
    } else {
      try {
        const response = await axios.post(`${baseUrl}/user/forgot-password/`, {
          email: username,
        });

        if (response.status === 200) {
          const responseData = response?.data?.message;
          toast.success(responseData);
          setShowForgetPasswordModal(false);
        } else {
          toast.error(ERROR_MESSAGES.RESET_PASSWORD_REQ);
        }
      } catch (error) {
        toast.error(error.response.data.message, {
          autoClose: 4000, // Set auto close time to 4000 milliseconds (4 seconds)
        });
      }
    }
  };

  const navigate = useNavigate();

  const handleLogin = (e) => {
    e.preventDefault();

    if (!isValidEmail(username)) {
      setEmailErrorMsg(ERROR_MESSAGES.LOGIN_VALID_EMAIL);
    } else {
      setEmailErrorMsg("");
    }

    if (!password) {
      setPasswordErrorMsg(ERROR_MESSAGES.VALID_PASSWORD);
    } else {
      setPasswordErrorMsg("");
    }

    if (isValidEmail(username) && password) {
      const loginURL = `${baseUrl}/${AUTH.LOGIN}`;
      axios
        .post(loginURL, {
          email: username,
          password: password,
        })
        .then((response) => {
          if (response.data.status === "success") {
            LocalStorage.login(response.data);
            navigate(ROUTES.LANDING_PAGE);
          } else {
            setPasswordErrorMsg(ERROR_MESSAGES.VALID_PASSWORD);
          }
        })
        .catch((err) => {
          toast.error(err.response?.data?.message);
        });
    }
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    if (currentPassword.length === 0) {
      setPasswordErrorMsg(ERROR_MESSAGES.CURRENT_PASSWORD_REQ);
    } else if (newPassword.length === 0) {
      setPasswordErrorMsg("");
      setNewPasswordErrorMsg(ERROR_MESSAGES.NEW_PASSWORD_REQ);
    } else if (!isValidNewPassword()) {
      setPasswordErrorMsg("");
      setNewPasswordErrorMsg(ERROR_MESSAGES.PASSWORD_CONDITION_FAILED);
    } else if (isValidNewPassword()) {
      if (!isValidConfirmPassword()) {
        setNewPasswordErrorMsg("");
        setConfirmPasswordErrorMsg(ERROR_MESSAGES.PASSWORD_MATCH);
      } else {
        setConfirmPasswordErrorMsg("");
        setNewPasswordErrorMsg("");
      }
    } else {
      setNewPasswordErrorMsg("");
    }

    if (isValidNewPassword() && isValidConfirmPassword() && currentPassword) {
      const passwordData = {
        oldPassword: currentPassword,
        newPassword: newPassword,
      };
      try {
        const response = await editHelper(
          AUTH.UPDATE_PASSWORD,
          passwordData,
          SUCCESS_MESSAGES.RESET_PASSWORD,
          ERROR_MESSAGES.TRY_AGAIN
        );
        if (response) {
          LocalStorage.logout();
          setResetPasswordModal(false);
        }
      } catch (error) {
        toast.error(ERROR_MESSAGES.TRY_AGAIN);
      }
    }
  };

  const handleBackToLanding = () => {
    navigate(ROUTES.LANDING_PAGE);
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

  const isValidNewPassword = () => {
    const hasUpperCase = /[A-Z]/.test(newPassword);
    const hasNumber = /\d/.test(newPassword);
    const hasSpecialChar = /[!@#$%^&*()_+]/.test(newPassword);
    const isMinLength = newPassword.length >= 8;

    return hasUpperCase && hasNumber && hasSpecialChar && isMinLength;
  };
  const isValidConfirmPassword = () => {
    return newPassword === confirmPassword;
  };

  const handleBackToLogin = () => {
    setShowForgetPasswordModal(false);
  };

  const handleChange = (e, setter) => {
    setter(e.target.value);
  };
  const handleToForgotPage = () => {
    setShowForgetPasswordModal(true);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="h-screen md:px-10  lg:px-40 flex  items-center justify-center">
      <div className="flex flex-col md:flex-row justify-between bg-white items-center px-10   lg:px-16">
        {/* Left side with image */}

        <div className="hidden md:flex bg-white md:w-[50%] flex-col py-10 lg:py-14 gap-y-20 h-full pr-16 md:border-r-2">
          <div className="flex justify-center items-center gap-x-3">
            <img
              src={images.bar_chart_logo}
              className="size-7"
              alt={LOGIN_PAGE_LABELS.IMG_ALT}
            />
            <div className="text-base font-semibold">{PROJECT_TITLE}</div>
          </div>
          <img
            src={images.drishti_logo}
            className=""
            alt={LOGIN_PAGE_LABELS.IMG_ALT}
          />
        </div>
        <div className="bg-white md:w-[50%] h-full md:pl-16">
          {(!resetPasswordModal &&
            ((!showForgetPasswordModal && (
              <LoginPage
                handleToForgotPage={handleToForgotPage}
                username={username}
                setUsername={setUsername}
                emailErrorMsg={emailErrorMsg}
                password={password}
                showPassword={showPassword}
                handleLogin={handleLogin}
                handleChange={handleChange}
                isValidEmail={isValidEmail}
                setPassword={setPassword}
                togglePasswordVisibility={togglePasswordVisibility}
                passwordErrorMsg={passwordErrorMsg}
              />
            )) || (
              <ForgotPassword
                handleForgetPassword={handleForgetPassword}
                handleChange={handleChange}
                handleBackToLogin={handleBackToLogin}
                setUsername={setUsername}
                username={username}
                isValidEmail={isValidEmail}
                emailErrorMsg={emailErrorMsg}
              />
            ))) || (
            <ResetPassword
              currentPassword={currentPassword}
              setCurrentPassword={setCurrentPassword}
              handleChange={handleChange}
              passwordErrorMsg={passwordErrorMsg}
              newPassword={newPassword}
              setNewPassword={setNewPassword}
              newPasswordErrorMsg={newPasswordErrorMsg}
              confirmPassword={confirmPassword}
              setConfirmPassword={setConfirmPassword}
              confirmPasswordErrorMsg={confirmPasswordErrorMsg}
              handleResetPassword={handleResetPassword}
              handleBackToLanding={handleBackToLanding}
              togglePasswordVisibility={togglePasswordVisibility}
              showPassword={showPassword}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default React.memo(LoginForm);
