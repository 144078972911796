import React from "react";

const Container = ({ children }) => {
  return (
    <div className="w-full mx-auto px-4 sm:px-6 md:px-8 lg:px-16">
      {children}
    </div>
  );
};

export default Container;
