export const AUTH = {
  LOGIN: `user/login`,
  REGISTER: `/register`,
  UPDATE_PASSWORD: "/user/update-password",
};
export const USER = {
  GET_ALL_USERS: `data/get`,
  CREATE_USER: `data/create`,
  GET_BY_ID: `data/get-by-employee-id`,
  EDIT_BY_ID: `data/update-by-employee-id`,
};

export const ISSUES = {
  CREATE_ISSUE: `issues/create`,
  UPLOAD_DOC: `/documents/upload`,
  GET_ALL_MY_ISSUES: `/issues`,
  GET_HR_ISSUES: `/issues/department/HR`,
};

const backendURL = process.env.REACT_APP_BACKEND_URL;

export default backendURL;
