import * as React from "react";
import { useNavigate } from "react-router-dom";
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Transition,
} from "@headlessui/react";
import { ROUTES } from "../utils/constants/routes";
import LocalStorage from "../utils/StorsgeUtil/LocalStorage";
import {
  LOGIN_PAGE_LABELS,
  PROJECT_TITLE,
  RESET_PASSWORD_PAGE_LABELS,
} from "../utils/constants/keywords";
const Icon = ({ src, alt }) => (
  <img loading="lazy" src={src} alt={alt} className="aspect-square w-7" />
);

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function Header() {
  const navigate = useNavigate();
  const { name } = JSON.parse(LocalStorage.getUserData());
  const handleLogout = () => {
    LocalStorage.logout();
    navigate(ROUTES.LOGIN);
  };
  const handleResetPassword = () => {
    navigate(ROUTES.LOGIN, { state: { resetPassword: true } });
  };
  return (
    <section className="flex justify-between px-20 align-items-middle h-16 max-h-16  pb-2 pt-4 w-full bg-white max-md:flex-wrap max-md:px-5 max-md:max-w-full">
      <div className="flex items-center gap-x-5">
        <div
          className="font-bold text-lg text-[#1D4ED8] cursor-pointer"
          onClick={() => {
            navigate(ROUTES.LANDING_PAGE);
          }}
        >
          {PROJECT_TITLE}
        </div>
      </div>
      <div className="flex gap-4">
        <Menu as="div" className="relative ">
          <div>
            <MenuButton className="relative ">
              <span className="absolute -inset-1.5" />
              <span className="sr-only">Open user menu</span>
              <div className="h-10 w-10 rounded-full bg-[#ACCBFF] shadow-sm border flex justify-center items-center">
                <div className="font-bold text-lg text-[#111827]">
                  {name &&
                    name[0].toUpperCase() +
                      ((name.includes(" ") &&
                        name[name.indexOf(" ") + 1]?.toUpperCase()) ||
                        "")}
                </div>
              </div>
            </MenuButton>
          </div>
          <Transition
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <MenuItems className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <MenuItem>
                {({ focus }) => (
                  <div
                    className={classNames(
                      (focus && "bg-gray-100") || "",
                      "block px-4 py-2 text-sm text-gray-700 cursor-pointer"
                    )}
                    onClick={handleResetPassword}
                  >
                    {RESET_PASSWORD_PAGE_LABELS.TITLE}
                  </div>
                )}
              </MenuItem>
              <MenuItem>
                {({ focus }) => (
                  <div
                    className={classNames(
                      (focus && "bg-gray-100") || "",
                      "block px-4 py-2 text-sm text-gray-700 cursor-pointer"
                    )}
                    onClick={handleLogout}
                  >
                    {LOGIN_PAGE_LABELS.LOGOUT}
                  </div>
                )}
              </MenuItem>
            </MenuItems>
          </Transition>
        </Menu>
      </div>
    </section>
  );
}

export default Header;
