const images ={
    my_career  :require('./my_career.webp'),
    my_data : require("./my_data.webp"),
    my_issue : require('./my_issue.webp'),
    my_task : require('./my_task.webp'),
    my_time_sheet : require('./my_time_sheet.webp'),
    work_force_management : require('./work_force_management.webp'),
    bar_chart_logo : require('./bar_chart_logo.webp'),
    drishti_logo : require('./drishti_logo.webp')
}
export default images