export const ERROR_MESSAGES = {
  LOGIN_VALID_EMAIL: "Please enter a valid email address",
  RESET_PASSWORD_REQ: "Password reset request failed.",
  VALID_PASSWORD: "Enter a valid password",
  CURRENT_PASSWORD_REQ: "Current password is required",
  NEW_PASSWORD_REQ: "New password is required",
  PASSWORD_CONDITION_FAILED:
    "Password must contain at least one uppercase letter, one number, one special character, and be at least eight characters long",
  PASSWORD_MATCH: "Passwords must match",
  TRY_AGAIN: "Something went wrong, please try again",

  profilePhoto: {
    required: "Profile photo is required",
  },
  employeeId: {
    required: "Employee ID is required",
    min: "Employee ID must be at least 3 characters long",
  },
  name: {
    required: "Name is required",
  },
  contactNumber: {
    required: "Contact number is required",
    matches: "Contact number must start with 9 or 6 and be 10 digits long",
  },
  role: {
    required: "Role is required",
    "is-not-select": "Role is required",
  },
  email: {
    required: "Email is required",
    email: "Please enter a valid email",
  },
  dateOfBirth: {
    required: "Date of birth is required",
  },
  fatherName: {
    required: "Father name is required",
  },
  motherName: {
    required: "Mother name is required",
  },
  maritalStatus: {
    required: "Marital Status is required",
    "is-not-select": "Marital Status is required",
  },
  bloodGroup: {
    required: "Blood group is required",
    "is-not-select": "Blood group is required",
  },
  emergencyContactName: {
    required: "Emergency contact name is required",
  },
  emergencyContactNumber: {
    required: "Emergency contact number is required",
    matches:
      "Emergency contact number must start with 9 or 6 and be 10 digits long",
  },
  nationality: {
    required: "Nationality is required",
  },
  aadhaarNo: {
    required: "Aadhar number is required",
    matches: "Aadhar number must be exactly 12 digits",
  },
  panCardNo: {
    required: "Pan card number is required",
    matches: "Invalid PAN card format",
  },
  passportNo: {
    required: "Passport number is required",
  },
  addressLineOne: {
    required: "Address is required",
  },
  zipCode: {
    required: "Zip code is required",
    matches: "Zip code must be exactly 6 digits",
  },
  skillSet: {
    required: "Skill set is required",
  },
  itSetUpTraining: {
    required: "IT setup training is required",
    "is-not-select": "IT setup training is required",
  },
  securityTraining: {
    required: "Security training is required",
    "is-not-select": "Security training is required",
  },
  fireDrill: {
    required: "Fire drill is required",
    "is-not-select": "Fire drill is required",
  },
  healthAndSafety: {
    required: "Health and safety is required",
    "is-not-select": "Health and safety is required",
  },
  posh: {
    required: "Posh is required",
    "is-not-select": "Posh is required",
  },
  others: {
    required: "Others is required",
  },
  bankName: {
    required: "Bank name is required",
  },
  bankAccountNo: {
    required: "Bank account number is required",
    matches: "Invalid bank account number",
  },
  branch: {
    required: "Branch is required",
  },
  ifsc: {
    required: "IFSC is required",
  },
  pfNumber: {
    required: "PF number is required",
  },
  uan: {
    required: "UAN is required",
  },
  pfJoining: {
    required: "PF joining date is required",
  },

  education: {
    level: {
      required: "Education level is required",
      "is-not-select": "Education level is required",
    },
    university: {
      required: "University is required",
    },
    fieldOfStudy: {
      required: "Field of study is required",
    },
    startDate: {
      required: "Start date is required",
    },
    endDate: {
      required: "End date is required",
      min: "End date cannot be before start date",
    },
    percentage: {
      required: "Grades/Percentage is required",
      typeError: "Grades/Percentage must be a number",
    },
  },

  certification: {
    certificationName: {
      required: "Certification name is required",
    },
    institute: {
      required: "Institute name is required",
    },
  },

  previousEmployments: {
    designation: {
      required: "Designation is required",
    },
    employmentType: {
      required: "Employment type is required",
      "is-not-select": "Employment type is required",
    },
    totalRelevantExperience: {
      required: "Total relevant experience is required",
    },
    companyName: {
      required: "Company name is required",
    },
    ctcInLPA: {
      required: "CTC in LPA is required",
      typeError: "CTC must be a number",
    },
  },

  currentEmployment: {
    designation: {
      required: "Designation is required",
    },
    employmentType: {
      required: "Employment type is required",
      "is-not-select": "Employment type is required",
    },
    client: {
      required: "Client is required",
      "is-not-select": "Client is required",
    },
    tagging: {
      required: "Tagging is required",
      "is-not-select": "Tagging is required",
    },
    status: {
      required: "Status is required",
      "is-not-select": "Status is required",
    },
    myProject: {
      required: "My project is required",
      "is-not-select": "My project is required",
    },
    supervisor: {
      required: "Supervisor is required",
    },
    startDate: {
      required: "Start date is required",
    },
    endDate: {
      required: "End date is required",
      min: "End date cannot be before start date",
    },
  },
  SELECT_DEPARTMENT: "Please select a department",
  REASSIGN_ERROR: "An error occurred while reassigning the issue.",
  UNKNOWN_ERROR: "Unknown error occurred during reassignment",
  FUNCTION_ERROR: "onReassign is not a function",
};
export const SUCCESS_MESSAGES = {
  PROFILE_UPDATE: "Successfully updated MyData",
  PROFILE_CREATE: "Successfully created MyData",
  RESET_PASSWORD: "Password reset successfully.",

  REASSIGN_SUCCESS: "Issue reassigned successfully",
};
