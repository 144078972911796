import React from "react";

const EmployeeHeader = ({ empName, empId }) => (
  <div className="relative flex items-center space-x-3   border-b  px-6 py-5">
    <div className="h-16 w-16 rounded-full bg-[#ACCBFF] shadow-sm border flex justify-center items-center">
      <div className="font-bold text-lg text-[#111827]">
        {empName &&
          empName[0].toUpperCase() +
            ((empName.includes(" ") &&
              empName[empName.indexOf(" ") + 1]?.toUpperCase()) ||
              "")}
      </div>
    </div>
    <div className="min-w-0 flex-1">
      <a href="#" className="focus:outline-none">
        <span className="absolute inset-0" aria-hidden="true" />
        <p className="text-xl font-semibold text-gray-900">{empName}</p>
        <p className="truncate text-base font-medium text-[#6B7280]">
          Employee ID: {empId}
        </p>
      </a>
    </div>
  </div>
);
export default React.memo(EmployeeHeader);
