import React from "react";
import SectionHeader from "./SectionHeader";
import TextInputField from "./TextInputField";

const EmployeeArrayInputFields = ({
    inputs,
    formik,
    handleNestedChange,
    heading,
    description,
    addSection,
    handleAddDetail,
    hideAdd
  }) => (
    <>
      <div className="flex justify-between">
        <SectionHeader heading={heading} description={description} />
        <div hidden={hideAdd}
          className="text-base text-[#9CA2AE] font-normal cursor-pointer justify-end "
          onClick={handleAddDetail}
        >
          + ADD
        </div>
      </div>
      <div className="mt-10">
        {addSection?.map((detailItems) => (
          <div key={detailItems.id}>
            <div
              className={`mb-16 ${
                inputs.length !== 1
                  ? "grid grid-cols-1 gap-x-20 gap-y-8 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4"
                  : null
              }`}
            >
              {inputs?.map((input) => (
                <TextInputField
                  key={input.id}
                  input={input}
                  value={
                    formik.values[input.array]?.[detailItems.detail]?.[input.name]
                  }
                  handleChange={(e) =>
                    handleNestedChange(
                      input.array,
                      detailItems.detail,
                      input.name,
                      e.target.value
                    )
                  }
                  touched={
                    formik.touched[input.array]?.[detailItems.detail]?.[
                      input.name
                    ]
                  }
                  errors={
                    formik.errors[input.array]?.[detailItems.detail]?.[input.name]
                  }
                />
              ))}
            </div>
          </div>
        ))}
      </div>
    </>
  );

  export default React.memo(EmployeeArrayInputFields)